.rugby-bracket-wrap {
  max-width: 1300px;
  margin: 0 auto;
  padding: 25px 0 50px;
  @include transition(all 0.5s ease-out 0s);
  @include breakpoints(xLarge) {
    margin: 0 auto;
    max-width: 1120px;
  }
  @include breakpoints(large) {
    margin: 0 auto;
    max-width: 1000px;
  }
  h1 {
    font-size: 33px;
    font-family: $industry-bold;
    color: $primary-color-blue;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    @include breakpoints(large) {
      font-size: 28px;
    }
    @include breakpoints(small) {
      font-size: 18px;
      margin-bottom: 20px;
    }
    span {
      color: $green;
      padding-left: 5px;
    }
    a {
      font-size: 14px;
      letter-spacing: 0.8px;
      text-decoration: underline;
      font-family: $industry-Medium;
      color: $primary-color-blue;
      margin-left: 14px;
    }
  }
  .bracket-wrapper-inner {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    overflow: hidden;
    .brackets {
      width: 100%;
      overflow: visible;
      margin: 60px 0 0;
      position: relative;

      @include breakpoints(medium) {
        margin-top: 0px;
        &.scrollableBracket {
          overflow-x: scroll;
          overflow-y: hidden;
        }
      }
      &.hbreakers {
        height: 750px;
        &.m_women {
          height: 520px;
          @include breakpoints(medium) {
            height: 925px;
          }
          @include breakpoints(small) {
            height: 925px;
            &.hbreakers-1 {
              height: 500px;
            }
            &.hbreakers-2 {
              height: 450px;
            }
          }
        }
        @include breakpoints(medium) {
          width: 100%;
          margin-left: 5px;
          margin-right: auto;
          height: auto;
        }
      }
      &.survivor-bracket-wrap {
        width: 100%;
        @include breakpoints(medium) {
          margin-top: 40px;
        }
        .survivor-bracket-outer {
          width: 100%;
          overflow-x: auto;
          @include transition(all 0.5s ease-out 0s);
          @include breakpoints(large) {
            width: 97%;
            margin-left: auto;
            margin-right: auto;
          }
          @include breakpoints(small) {
            width: 95%;
          }
          .survivor-bracket-inner {
            position: relative;
            width: 1050px;
            margin: 0 auto;
            height: 275px;
            z-index: 10;
          }
        }
      }
      &.challenge-bracket-wrap {
        width: 100%;
        overflow-x: auto;
        padding-top: 6px;
        @include breakpoints(medium) {
          margin-top: 40px;
        }
        .challenge-bracket-outer {
          width: 100%;
          overflow-x: auto;
          @include transition(all 0.5s ease-out 0s);
          @include breakpoints(small) {
            width: 95%;
            margin-top: 45px;
            margin-left: auto;
            margin-right: auto;
          }
          .challenge-bracket-inner {
            position: relative;
            width: 720px;
            margin: 48px auto 0;
            height: 220px;
            z-index: 10;
            @include breakpoints(medium) {
              margin-top: 10px;
            }
          }
        }
      }
      &.champion-bracket-wrap {
        width: 100%;
        overflow-x: auto;
        padding-top: 6px;
        &.womens {
          overflow-x: hidden;
        }
        .bracket-bottom-text {
          &.womens {
            width: 525px !important;
            top: 225px;
          }
        }

        @include breakpoints(medium) {
          margin-top: 40px;
        }
        .champion-bracket-outer {
          width: 100%;
          overflow-x: auto;
          overflow-y: hidden;
          @include transition(all 0.5s ease-out 0s);
          @include breakpoints(small) {
            width: 95%;
            margin-left: auto;
            margin-top: 40px;
            margin-right: auto;
          }
          .champion-bracket-inner {
            position: relative;
            width: 720px;
            overflow-y: hidden;
            margin: 35px auto 0;
            height: 240px;
            z-index: 10;
            &.womens {
              height: 270px;
              max-width: 525px;
              width: auto;
            }
            @include breakpoints(medium) {
              margin-top: 10px;
            }
          }
        }
      }

      .bracket-challenge-head {
        text-align: center;
        letter-spacing: 12px;
        margin-top: 70px;
        font-size: 30px;
        color: $primary-color-blue;
        font-family: $industry-bold;
        padding: 25px 0;
        @include breakpoints(large) {
          font-size: 24px;
        }
        @include breakpoints(medium) {
          font-size: 17px;
          padding: 15px;
          letter-spacing: 6px;
        }
        @include breakpoints(small) {
          letter-spacing: 3px;
        }
      }
      .premier-cup-head {
        font-size: 36px;
        font-family: $industry-bold;
        color: $green;
        font-style: italic;
        letter-spacing: 4.5px;
        line-height: 1.2;
        text-align: center;
        transform: translateY(-23px);
        font-weight: 700;
        @include breakpoints(large) {
          font-size: 28px;
        }
        @include breakpoints(medium) {
          transform: translateY(0);
          font-size: 20px;
        }
      }
      .logo-brackets-bottom {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        bottom: 3%;
        width: 219px;
        @include breakpoints(medium) {
          display: none;
        }
      }
      .bracket-rouds-wrap {
        margin: 0;
        width: 100%;
        bottom: 100%;
        left: 0;
        padding: 0 0 0 5px;
        position: absolute;
        height: 60px;
        list-style: none;
        @include breakpoints(medium) {
          bottom: unset;
          top: 0;
        }
        @include transition(all 0.5s ease-out 0s);
        .round-tab {
          width: 135px;
          text-align: center;
          letter-spacing: 0.5px;
          box-sizing: border-box;
          position: absolute;
          cursor: default;
          @include transition(all 0.5s ease-out 0s);
          @include breakpoints(xLarge) {
            width: 115px;
          }
          @include breakpoints(large) {
            width: 105px;
          }
          @include breakpoints(medium) {
            width: 140px;
          }
          .r_name {
            background-color: $primary-color-blue;
            color: $tab-color;
            font-family: $mirpuri-bold;
            padding: 8px 0;
            font-size: 14px;
            display: block;
            margin-bottom: 5px;
            @include breakpoints(xLarge) {
              font-size: 11px;
            }
            @include breakpoints(medium) {
              font-size: 13px;
              letter-spacing: 1px;
            }
          }
          .r_date {
            font-family: $industry-Medium;
            font-size: 12px;
            color: #404040;
          }
          &.arrow-left {
            width: 26px;
            height: 35px;
            cursor: pointer;
            display: none;
            z-index: 1;
            left: -5px;
            // @include breakpoints(medium){
            //     display: block;
            // }
            span {
              display: block;
              position: relative;
              background-color: #bcbdbf;
              height: 40px;
              &:before {
                content: "";
                position: absolute;
                left: 10px;
                top: 10px;
                border: solid black;
                border-width: 0 2px 2px 0;
                padding: 6px;
                transform: rotate(135deg);
              }
            }
          }
          &.r_1 {
            left: 5px;
            @include breakpoints(medium) {
              left: 32px;
            }
          }
          &.r_2 {
            left: 148px;
            @include breakpoints(xLarge) {
              left: 128px;
            }
            @include breakpoints(large) {
              left: 115px;
            }
            @include breakpoints(medium) {
              left: 215px;
            }
          }
          &.r_3 {
            left: 292px;
            @include breakpoints(xLarge) {
              left: 252px;
            }
            @include breakpoints(large) {
              left: 225px;
            }
            @include breakpoints(medium) {
              left: 398px;
            }
          }
          &.r_4 {
            left: 436px;
            @include breakpoints(xLarge) {
              left: 376px;
            }
            @include breakpoints(large) {
              left: 335px;
            }
            @include breakpoints(medium) {
              left: 573px;
            }
          }
          &.championship {
            left: 44.8%;
            @include breakpoints(xLarge) {
              left: 50%;
              transform: translateX(-50%);
            }
            @include breakpoints(medium) {
              left: 750px;
              transform: translateX(0);
            }
          }
          &.r_4 ~ .r_4 {
            left: auto;
            right: 436px;
            @include breakpoints(xLarge) {
              right: 376px;
            }
            @include breakpoints(large) {
              right: 335px;
            }
            @include breakpoints(medium) {
              display: none;
            }
          }
          &.r_3 ~ .r_3 {
            left: auto;
            right: 292px;
            @include breakpoints(xLarge) {
              right: 252px;
            }
            @include breakpoints(large) {
              right: 225px;
            }
            @include breakpoints(medium) {
              display: none;
            }
          }
          &.r_2 ~ .r_2 {
            left: auto;
            right: 148px;
            @include breakpoints(xLarge) {
              right: 128px;
            }
            @include breakpoints(large) {
              right: 115px;
            }
            @include breakpoints(medium) {
              display: none;
            }
          }
          &.r_1 ~ .r_1 {
            left: auto;
            right: 5px;
            @include breakpoints(medium) {
              display: none;
            }
          }
        }
      }
      .mW {
        border: 2px solid #bcbdbf;
        border-width: 2px 2px 2px 0;
        height: 90px;
        margin: 30px 0;
        position: absolute;
        .regions-left,
        .regions-left-bottom,
        .regions-right,
        .regions-right-bottom {
          position: absolute;
          font-family: $industry-bold;
          font-size: 18px;
          font-style: italic;
          color: $green;
          @include transition(all 0.5s ease-out 0s);
          @include breakpoints(xLarge) {
            font-size: 14px;
          }
        }
        .regions-left,
        .regions-left-bottom {
          left: -98%;
          top: -7%;
          @include breakpoints(xLarge) {
            left: -100%;
          }
          @include breakpoints(medium) {
            left: -114px;
            font-size: 20px;
          }
        }
        .regions-left-bottom {
          top: auto;
          bottom: -5%;
          position: absolute;
        }
        .regions-right,
        .regions-right-bottom {
          right: -98%;
          top: -7%;
          @include breakpoints(xLarge) {
            right: -100%;
          }
          @include breakpoints(medium) {
            right: auto;
            left: -120px;
            font-size: 20px;
          }
        }
        .regions-right-bottom {
          top: auto;
          bottom: -5%;
          position: absolute;
        }
        .matchup {
          background: #fff;
          box-sizing: border-box;
          margin: 0;
          position: absolute;
          width: 190px;
          @include transition(all 0.5s ease-out 0s);
          box-shadow: 0 3px 16px -4px rgba(0, 0, 0, 0.12);
          // box-shadow:1px 1px 3px 1px rgb(0 0 0 / 20%);
          top: -35px;
          @include breakpoints(xLarge) {
            width: 150px;
          }
          @include breakpoints(large) {
            width: 140px;
          }
          @include breakpoints(medium) {
            width: 190px;
          }
          .slots {
            position: relative;
            .slots-inner {
              @include wrap-flx;
              justify-content: flex-start;
              align-items: center;
              border-bottom: 1px solid #f8f8f8;
              &:last-child {
                border: 0;
              }
              .bluebox {
                background-color: #f5f5f5;
                color: #333333;
                width: 33px;
                height: 35px;
                font-size: 14px;
                font-family: $mirpuri-bold;
                text-align: center;
                padding-top: 7px;
                @include breakpoints(xLarge) {
                  width: 18px;
                  font-size: 11px;
                }
                @include breakpoints(medium) {
                  width: 33px;
                  font-size: 12px;
                }
              }
              a {
                width: 80%;
                height: 30px;
                margin: 0 0 0 3px;
                display: inline-flex;
                align-items: center;
                text-decoration: none;
                color: $text-grey;
                justify-content: flex-start;
                padding: 0 5px 0;
                @include breakpoints(xLarge) {
                  width: 83%;
                }
                @include breakpoints(medium) {
                  width: 80%;
                }
                &.grey {
                  background-color: #f5f5f5;
                }
                &.s_pink {
                  background-color: $slot-pink;
                }
                &.s_green {
                  background-color: $slot-green;
                }
                span {
                  font-size: 12px;
                  font-family: $mirpuri-book;
                  @include breakpoints(xLarge) {
                    font-size: 10px;
                    line-height: 1.2;
                  }
                  @include breakpoints(medium) {
                    font-size: 12px;
                    line-height: 1.3;
                    &.active {
                      font-weight: bold;
                      font-family: $industry-bold !important;
                    }
                  }
                  &:first-child {
                    margin-right: 5px;
                  }
                  &:last-child {
                    margin-left: auto;
                  }
                }
              }
            }
          }
        }
        .matchup + .matchup {
          bottom: -35px;
          top: auto;
        }
      }
      .mW.mW_1,
      .mW.mW_2,
      .mW.mW_3,
      .mW.mW_4,
      .mW.mW_5,
      .mW.mW_6,
      .mW.mW_7,
      .mW.mW_8 {
        width: 200px;
        @include breakpoints(xLarge) {
          width: 160px;
        }
        @include breakpoints(large) {
          width: 147px;
        }
        @include breakpoints(medium) {
          width: 200px;
        }
      }
      .mW.mW_1,
      .mW.mW_2,
      .mW.mW_3,
      .mW.mW_4 {
        left: 0;
      }
      .mW.mW_1,
      .mW.mW_5 {
        top: 90px;
      }
      .mW.mW_2,
      .mW.mW_6 {
        top: 260px;
      }
      .mW.mW_3,
      .mW.mW_7 {
        top: 430px;
      }
      .mW.mW_4,
      .mW.mW_8 {
        top: 600px;
      }
      .mW.mW_5,
      .mW.mW_6,
      .mW.mW_7,
      .mW.mW_8 {
        right: 0;
        border-left-width: 2px;
        border-right-width: 0;
        @include breakpoints(medium) {
          right: auto;
          left: 0;
          border-width: 2px 2px 2px 0;
        }
      }
      @include breakpoints(medium) {
        .mW.mW_5 {
          top: 770px;
        }
        .mW.mW_6 {
          top: 940px;
        }
        .mW.mW_7 {
          top: 1110px;
        }
        .mW.mW_8 {
          top: 1280px;
        }
      }
      .mW.mW_5,
      .mW.mW_6,
      .mW.mW_7,
      .mW.mW_8 {
        .matchup {
          left: auto;
          right: 0;
          @include breakpoints(medium) {
            left: 0;
            right: auto;
          }
          .slots {
            .slots-inner {
              justify-content: flex-end;
              @include breakpoints(medium) {
                justify-content: flex-start;
              }
              .bluebox {
                order: 2;
                @include breakpoints(medium) {
                  order: 1;
                }
              }
              a {
                order: 1;
                margin: 0 3px 0 0;
                @include breakpoints(medium) {
                  order: 2;
                  margin: 0 0 0 3px;
                }
              }
            }
          }
        }
      }
      // second col
      .mW.mW_9,
      .mW.mW_10,
      .mW.mW_11,
      .mW.mW_12,
      .mW.mW_13,
      .mW.mW_14,
      .mW.mW_15,
      .mW.mW_16,
      .mW.mW_17 {
        width: 90px;
        left: 200px;
        height: 173px;
        @include transition(all 0.5s ease-out 0s);
        @include breakpoints(xLarge) {
          left: 160px;
          width: 106px;
        }
        @include breakpoints(large) {
          left: 145px;
          width: 77px;
        }
        .matchup {
          left: 8px;
          width: 155px;
          @include breakpoints(xLarge) {
            width: 135px;
          }
          @include breakpoints(large) {
            width: 125px;
          }
          @include breakpoints(medium) {
            width: 155px;
          }
          .slots {
            padding: 2px;
            .slots-inner {
              height: 30px;
              a {
                width: 100%;
                margin: 0;
              }
              &:first-child {
                a {
                  border-bottom: 1px solid #ffffff;
                }
              }
              &:last-child {
                a {
                  border-top: 1px solid #ffffff;
                }
              }
            }
          }
        }
      }
      .mW.mW_11,
      .mW.mW_12 {
        left: auto;
        right: 200px;
        border-left-width: 2px;
        border-right-width: 0;
        @include breakpoints(xLarge) {
          right: 160px;
        }
        @include breakpoints(large) {
          right: 145px;
        }
        .matchup {
          right: 8px;
          left: auto;
        }
      }
      .mW.mW_9,
      .mW.mW_11 {
        top: 135px;
      }
      .mW.mW_10,
      .mW.mW_12 {
        top: 475px;
      }
      @include breakpoints(medium) {
        .mW.mW_9,
        .mW.mW_10,
        .mW.mW_11,
        .mW.mW_12 {
          left: 200px;
          width: 173px;
        }
        .mW.mW_11,
        .mW.mW_12 {
          border-width: 2px 2px 2px 0;
          .matchup {
            left: 8px;
            right: auto;
          }
        }
        .mW.mW_11 {
          top: 815px;
        }
        .mW.mW_12 {
          top: 1155px;
        }
      }
      // third col
      .mW.mW_13,
      .mW.mW_14 {
        left: 400px;
      }
      .mW.mW_13,
      .mW.mW_14 {
        top: 225px;
        height: 336px;
        left: 290px;
        @include breakpoints(xLarge) {
          left: 266px;
          width: 70px;
        }
        @include breakpoints(large) {
          left: 222px;
          width: 70px;
        }
        @include breakpoints(medium) {
          left: 373px;
          width: 173px;
        }
      }
      .mW.mW_14 {
        left: auto;
        right: 290px;
        border-left-width: 2px;
        border-right-width: 0;
        @include breakpoints(xLarge) {
          right: 266px;
        }
        @include breakpoints(large) {
          right: 222px;
        }
        @include breakpoints(medium) {
          border-width: 2px 2px 2px 0;
          top: 900px;
          right: auto;
          left: 373px;
        }
        .matchup {
          right: 8px;
          left: auto;
          @include breakpoints(medium) {
            right: auto;
            left: 8px;
          }
        }
      }
      // fourth col

      .mW.mW_15,
      .mW.mW_16 {
        left: 380px;
        height: 13px;
        width: 176px;
        border-width: 2px 0 0;
        top: 390px;
        &.mw_women {
          top: 210px;
          @include breakpoints(medium) {
            left: 371px;
          }
          &.m15Sec {
            left: 373px;
            top: 308px;
            height: 173px !important;
          }
          &.m15Sec1 {
            left: 375px;
            top: 325px;
            height: 173px !important;
          }
          &.m16Sec {
            left: 373px;
            top: 860px;
          }
          &.m16Sec1 {
            left: 374px;
            top: 972px;
          }
        }

        @include breakpoints(xLarge) {
          left: 336px;
          width: 150px;
        }
        @include breakpoints(large) {
          left: 292px;
          width: 140px;
        }
        @include breakpoints(medium) {
          left: 546px;
          width: 173px;
          border-width: 2px 2px 0 0;
          height: 686px;
        }
        .matchup {
          left: 8px;
        }
      }
      .mW.mW_16 {
        left: auto;
        right: 380px;
        @include breakpoints(xLarge) {
          right: 336px;
        }
        @include breakpoints(large) {
          right: 292px;
        }
        @include breakpoints(medium) {
          right: auto;
          left: 546px;
          top: 1075px;
          height: 20px;
          border-width: 2px 0 0;
          &.mw_women {
            top: 562px;
          }
        }
      }
      // fifth col
      .mW.mW_17 {
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 380px;
        height: 80px;
        width: 188px;
        border-width: 2px;
        &.mw_women {
          top: 200px;
        }
        @include transition(all 0.3s ease-out 0s);
        @include breakpoints(xLarge) {
          width: 150px;
        }
        @include breakpoints(large) {
          width: 140px;
        }
        @include breakpoints(medium) {
          width: 160px;
          left: 714px;
          top: 600px;
          border-width: 2px 0 2px 2px;
          height: 395px;
        }
        .matchup {
          left: 15px;
          @include breakpoints(xLarge) {
            left: 5px;
          }
          @include breakpoints(medium) {
            left: 10px;
          }
        }
        .nat-champion-text,
        .third-place-text {
          position: absolute;
          top: -57px;
          left: 0;
          right: 0;
          margin: 0 auto;
          text-align: center;
          letter-spacing: 0.3px;
          font-size: 12px;
          font-family: $industry-bold;
          color: $primary-color-blue;
          font-weight: 700;
          &.active {
            top: -72px;
            div {
              font-family: $industry-Medium;
            }
          }
          @include breakpoints(xLarge) {
            left: -8px;
            width: 162px;
          }
          @include breakpoints(medium) {
            left: 5px;
          }
        }
        .third-place-text {
          bottom: -57px;
          top: auto;
        }
      }
      .submit-bracket {
        text-align: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        input {
          &.submit-style {
            background-color: $primary-color-blue;
            color: #ffffff;
            border: none;
            cursor: pointer;
            width: 256px;
            height: 46px;
            @include border-radius(4px);
            letter-spacing: 1px;
            font-size: 14px;
            font-family: $industry-bold;
          }
        }
        input[disabled] {
          background: #d5d5d5;
          color: #eeeeee;
        }
      }
      // survivor cup
      .mW.mW_18,
      .mW.mW_19,
      .mW.mW_20,
      .mW.mW_21,
      .mW.mW_22,
      .mW.mW_23,
      .mW.mW_24,
      .mW.mW_25,
      .mW.mW_26,
      .mW.mW_27,
      .mW.mW_28,
      .mW.mW_29,
      .mW.mW_30,
      .mW.mW_31,
      .mW.mW_32,
      .mW.mW_33,
      .mW.mW_34,
      .mW.mW_35,
      .mW.mW_36 {
        width: 165px;
        left: 0;
        height: 80px;
        @include transition(all 0.5s ease-out 0s);
        .matchup {
          left: 0;
          width: 155px;
          .slots {
            padding: 2px;
            .slots-inner {
              height: 30px;
              a {
                width: 100%;
                margin: 0;
              }
              &:first-child {
                a {
                  border-bottom: 1px solid #ffffff;
                }
              }
              &:last-child {
                a {
                  border-top: 1px solid #ffffff;
                }
              }
            }
          }
        }
      }
      .mW.mW_19,
      .mW.mW_21 {
        top: 165px;
      }
      .mW.mW_20,
      .mW.mW_21 {
        left: auto;
        right: 0;
        border-left-width: 2px;
        border-right-width: 0;
        .matchup {
          right: 0;
          left: auto;
        }
      }
      .mW.mW_22,
      .mW.mW_23 {
        left: 165px;
        width: 100px;
        top: 40px;
        height: 168px;
        .matchup {
          left: 8px;
        }
      }
      .mW.mW_23 {
        left: auto;
        right: 165px;
        border-left-width: 2px;
        border-right-width: 0;
        .matchup {
          left: auto;
          right: 8px;
        }
      }
      .mW.mW_24,
      .mW.mW_25 {
        left: 265px;
        width: 173px;
        top: 125px;
        height: 18px;
        border-width: 2px 0 0;
        .matchup {
          left: 8px;
        }
      }
      .mW.mW_25 {
        left: auto;
        right: 265px;
        .matchup {
          left: auto;
          right: 8px;
        }
      }
      .mW.mW_26 {
        left: 0;
        right: 0;
        margin: 0 auto;
        border-width: 2px;
        width: 175px;
        top: 120px;
        .matchup {
          left: 8px;
        }
      }

      // Challenger and champion cup bracaket
      .mW.mW_27,
      .mW.mW_28,
      .mW.mW_32,
      .mW.mW_33 {
        left: 0;
        top: 0;
        border-width: 0 2px 0 0;
        width: 100px;
        height: 168px;
      }
      .mW.mW_28,
      .mW.mW_33 {
        left: auto;
        right: 0;
        top: 0;
        border-width: 0 0 0 2px;
        .matchup {
          left: auto;
          right: 0;
        }
      }
      .mW.mW_29,
      .mW.mW_30,
      .mW.mW_34,
      .mW.mW_35 {
        left: 100px;
        height: 18px;
        border-width: 2px 0 0;
        width: 173px;
        top: 85px;
        .matchup {
          left: 8px;
        }
      }
      .mW.mW_30,
      .mW.mW_35 {
        left: auto;
        right: 100px;
        .matchup {
          left: auto;
          right: 8px;
        }
      }
      .mW.mW_31,
      .mW.mW_36 {
        left: 0;
        right: 0;
        margin: 0 auto;
        border-width: 2px;
        width: 175px;
        top: 75px;
        .matchup {
          left: 8px;
        }
      }

      .survivor-head,
      .challenge-head,
      .champion-head {
        position: relative;
        z-index: 0;
        font-family: $industry-bold;
        font-size: 36px;
        color: $green;
        font-style: italic;
        letter-spacing: 4.5px;
        text-align: center;
        text-transform: uppercase;
        margin: 0 auto;
        font-weight: 700;
        @include breakpoints(medium) {
          font-size: 28px;
          margin-bottom: 30px;
        }
        @include breakpoints(small) {
          font-size: 22px;
          margin-bottom: 10px;
          letter-spacing: 3px;
        }
        span {
          display: block;
          font-size: 18px;
          letter-spacing: 2.25px;
          font-style: normal;
          @include breakpoints(medium) {
            font-size: 14px;
          }
          @include breakpoints(small) {
            font-size: 12px;
            letter-spacing: 1px;
          }
        }
      }
      .bracket-bottom-text {
        font-size: 16px;
        letter-spacing: 0.6px;
        color: $brakt-red;
        text-align: center;
        font-family: $mirpuri-bold;
        line-height: 1.3;
        position: relative;
        bottom: 10px;
        z-index: 0;
        margin: 0 auto;
        font-weight: 700;
        @include breakpoints(large) {
          width: 1050px;
        }
        @include breakpoints(medium) {
          font-size: 14px;
        }
        &.challenge-bottom-text {
          bottom: -3px;
          left: 0;
          @include breakpoints(medium) {
            left: 0px;
          }
          @include breakpoints(large) {
            width: 720px;
          }
        }
      }
    }
  }
}
.blue-bracket-bottom-text {
  color: #1f5cbf !important;
}
@include breakpoints(medium) {
  .pairs-wrap {
    position: relative;
    top: 35px;
    width: 880px;
    height: 1485px;
    margin-top: -90px;
    @include transition(all 0.5s ease-out 0s);
  }
}

@include breakpoints(medium) {
  ////////////////////////
  .sec-pair {
    transform: translateX(-206px);
    height: 800px;
    &.womens {
      height: 450px;
    }
  }
  .sec-pair1 {
    transform: translateX(-380px);
    height: 600px;
  }
  .sec-pair2 {
    transform: translateX(-551px);
    height: 400px;
  }

  .secRd-left {
    transform: translateX(-198px);
  }
  .secRd-left1 {
    transform: translateX(-380px);
  }
  .secRd-left2 {
    transform: translateX(-565px);
  }

  // second col
  .m9Sec,
  .m10Sec,
  .m11Sec,
  .m12Sec {
    transform: translateY(-45px);
    height: 73px !important;
  }
  .m10Sec {
    transform: translateY(-232px);
  }
  .m11Sec {
    transform: translateY(-420px);
  }
  .m12Sec {
    transform: translateY(-608px);
  }
  // third col
  .m13Sec,
  .m14Sec {
    transform: translateY(-99px);
    height: 155px !important;
  }
  .m14Sec {
    transform: translateY(-469px);
  }
  // 3rd col quarter
  .m13Sec1,
  .m14Sec1 {
    transform: translateY(-115px);
    height: 105px !important;
  }
  .m14Sec1 {
    transform: translateY(-580px);
  }

  // 4th col and last
  .m15Sec,
  .m16Sec,
  .m17Sec {
    transform: translateY(-182px);
    height: 155px !important;
  }
  .m16Sec {
    transform: translateY(-564px);
  }
  .m17Sec {
    transform: translateY(-330px);
    &.mw_women {
      left: 544px !important;
      top: 492px !important;
    }
  }

  // 4th col and last quarter
  .m15Sec1,
  .m16Sec1,
  .m17Sec1 {
    transform: translateY(-227px);
    height: 125px !important;
  }
  .m16Sec1 {
    transform: translateY(-703px);
  }
  .m17Sec1 {
    transform: translateY(-435px);
    &.mw_women {
      top: 589px !important;
      left: 546px !important;
    }
  }
  // 4th col and last semi finals
  .m15Sec2,
  .m16Sec2,
  .m17Sec2 {
    transform: translateY(-270px);
    height: 2px !important;
  }
  .m16Sec2 {
    transform: translateY(-880px);
  }
  .m17Sec2 {
    transform: translateY(-575px);
    height: 85px !important;
  }

  // regions
  .regTop {
    transform: translate(95px, -50px);
  }
  .regBot {
    transform: translate(95px, -40px);
  }

  .regTop1 {
    transform: translate(95px, -55px);
  }
  .regBot1 {
    transform: translate(95px, -40px);
  }
}

.shift-arr1 {
  transform: translateX(198px);
  display: block !important;
}
.shift-arr2 {
  transform: translateX(380px);
  display: block !important;
}
.shift-arr3 {
  transform: translateX(565px);
  display: block !important;
}

.slots-inner {
  .active {
    font-weight: bold;
    font-family: $industry-bold !important;
  }
}

.scoreBox {
  z-index: 10;
  input {
    width: 30px;
    text-align: center;
    font-size: 12px;
  }
}
.teamTitle {
  width: 80px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tooltip-1 {
  position: relative;
  display: inline-block;
  &.disable-tooltip {
    .tooltiptext {
      display: none;
    }
  }
}

.tooltip-1 .tooltiptext {
  visibility: hidden;
  min-width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 10000000;
  bottom: 150%;
  left: -5px;
  margin-left: 0px;
}

.tooltip-1 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip-1:hover .tooltiptext {
  //visibility: visible;
}

.submit-bracket-2 {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  input {
    &.submit-style {
      background-color: $primary-color-blue;
      color: #ffffff;
      border: none;
      cursor: pointer;
      width: 256px;
      height: 46px;
      @include border-radius(4px);
      letter-spacing: 1px;
      font-size: 14px;
      font-family: $industry-bold;
    }
  }
}
.modal-open .modal {
  z-index: 10000000 !important;
}
.modal-open .modal {
  z-index: 10000000 !important;
  background-color: #0000000f !important;
}
.rugby-mens-bracket {
  .btn-primary {
    color: #fff !important;
    background-color: #14376f !important;
    border-color: #14376f !important;
    margin: 10px 5px !important;
    background-color: #0e4294 !important;
  }
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 250px !important;
    height: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 5px;
    outline: 0;
  }
  .modal-header {
    padding: 30px 10px 5px 10px !important;
    height: 110px;
    margin-left: -0.02px;
    align-items: center;
    background-color: #0e4294;
  }
  span {
    color: white !important;
    font-size: 14px;
  }
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 10px !important;
  }
  .nav-link {
    padding: 0 !important;
  }
  .bodyimg {
    width: 40% !important;
    @include breakpoints(small) {
      width: 20% !important;
    }
  }

  .modal-dialog {
    right: 0;
    left: 0;
    max-width: 450px !important;
    width: 100% !important;
    padding-top: 0% !important;
    padding-bottom: 30px;
    position: absolute;
  }
}

.slots-inner {
  z-index: 200;
}

.tooltiptext .team-1 {
  text-decoration: line-through;
}
.losingTeam {
  display: flex;
  flex-direction: column;
  .team-1 {
    font-size: 10px !important;
    text-decoration: line-through;
  }
  .team-2 {
    font-size: 10px !important;
    margin: 0 !important;
  }
}

.rugby-tie-bracker {
  .error-message {
    font-size: 10px;
    text-align: center;
    color: red;
  }
  .modal-dialog {
    transform: translate(0%, 15%) !important;
  }
  .modal-header {
    background-color: unset !important;
    height: 20px !important;
    padding: 4px !important;
    border: none !important;
  }
  .modal-body {
    padding: 0px 28px !important;
    .team-tiles {
      margin-top: 11px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      .tile-hiphen {
        font-family: $industry-bold;
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 25px;
      }
      .tile {
        width: 100%;
        .tile-box {
          width: 100%;
          height: 86px;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.03);
          border: solid 1px #c2c2c2;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .flag {
            font-size: 14px;
          }
          img {
            width: 30px;
            height: 30px;
            margin-top: 8px;
          }
          .score {
            font-family: $industry-bold;
            color: #14376f;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 1.85px;
            margin-top: 15px;
            text-align: center;
            width: 64px;
            line-height: 0px;
          }
        }
        .tile-text {
          font-family: "MaisonNeue";
          letter-spacing: 1.4px;
          color: #8d8d8d;
          font-size: 14px;
          line-height: 2.07;
          margin-top: 1px;
          text-align: center;
          font-weight: bold;
        }
      }
    }
    .tie-title {
      font-size: 22px;
      color: #14376f;
      font-family: $industry-bold;
      text-align: center;
      .tie-subtitle {
        font-size: 18px;
        font-family: "MaisonNeue";
      }
    }
    .tie-slots {
      width: 100%;
      height: 84px;
      box-shadow: 0 3px 16px -4px rgba(0, 0, 0, 0.12);
      .slot {
        margin: 3px;
        display: flex;
        align-items: center;
        font-size: 13px;
        font-family: "MaisonNeue";
        height: 37px;
        justify-content: space-between;
        color: #333333;
        &.green {
          background-color: #dafad9;
        }
        &.grey {
          background-color: #f5f5f5;
        }
        &.red {
          background-color: rgba(156, 0, 0, 0.15);
        }
        .team {
          display: flex;
          margin-left: 6px;
          .flag {
            margin-right: 5px;
          }
          .teamName {
            &.active {
              font-weight: bold;
              font-family: $industry-bold !important;
              &:before {
                content: "\2713 ";
                margin-right: 5px;
              }
            }
          }
        }
        .score {
          margin-right: 8px;
        }
      }
      .slot-2 {
        margin-top: 6px;
      }
    }
  }
}

.strikeThrough {
  text-decoration: line-through;
}

.matchup {
  &.error {
    border: 2px solid red;
  }
}

.third-place-text {
  position: absolute;
  margin: 0 auto;
  text-align: center;
  letter-spacing: 0.3px;
  font-size: 12px;
  color: #14376f;
  bottom: -55px;
  left: 60px;
  font-weight: 700;
}

.champion-text {
  position: absolute;
  margin: 0 auto;
  text-align: center;
  letter-spacing: 0.3px;
  font-size: 12px;
  // text-transform: uppercase;
  color: #14376f;
  top: -55px;
  left: 38px;
  font-weight: 700;
}

.s_green .strikeThrough {
  text-decoration: none;
}

.s_pink .strikeThrough {
  text-decoration: none;
}

.tournament-selector {
  display: none;
  @include breakpoints(small) {
    padding: 0px 10px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    label {
      font-family: "MaisonNeue";
      font-size: 14px;
      color: #424242;
      letter-spacing: 0;
      margin: 0;
      margin-right: 5px;
    }
    select {
      flex: 1;
    }
  }
}

.mW_9-1 {
  width: 15px;
  height: 177px !important;
  top: 132px;
  left: 365px;
  @include breakpoints(xLarge) {
    left: 305px;
    width: 31px;
  }
  @include breakpoints(medium) {
    width: 8px;
    height: 173px !important;
    top: 135px;
    left: 365px;
  }
  @include breakpoints(small) {
    height: 173px !important;
    top: 135px;
    width: 8px;
  }
  &.m9Sec {
    height: 73px !important;
    top: 135px;
    width: 8px;
  }
}

.mW_11-1 {
  right: 364px;
  top: 133px;
  height: 176px !important;
  width: 17px;
  border-right: none !important;
  border-left: 2px solid #bcbdbf !important;
  @include breakpoints(xLarge) {
    right: 303px;
    width: 33px;
  }
  @include breakpoints(small) {
    display: none;
  }
}

.mw_women.mW_9 {
  border-right: none !important;
}
.mw_women.mW_11 {
  border-left: none !important;
}

.mw_women {
  &.mW_34 {
    left: 0 !important;
    border: none !important;
  }
  &.mW_35 {
    border: none !important;
    right: 0 !important;
    left: 348px !important;
  }
  &.mW_36 {
    margin: 0 !important;
    left: 174px !important;
  }
}

.mw_women.mW_34-1 {
  left: 163px;
  width: 12px;
  top: 82px;
  border-top: none !important;
  height: 1px !important;
}
@include breakpoints(medium) {
  .mW_11-1 {
    display: none;
  }
  .mw_women {
    &.mW_5 {
      left: 0px !important;
      top: 430px !important;
    }
    &.mW_6 {
      top: 600px !important;
      left: 0 !important;
    }
    &.mW_11 {
      left: 200px !important;
      top: 474px !important;
    }
    &.mW_15 {
      height: 354px !important;
    }
    &.mW_17 {
      margin: 0px !important;
      left: 542px !important;
      height: 200px !important;
      top: 312px !important;
    }
  }
}
@include breakpoints(small) {
  .mw_women {
    &.mW_5 {
      top: 430px !important;
    }
    &.mW_6 {
      top: 600px !important;
    }
    &.mW_11 {
      top: 476px !important;
      &.m11Sec {
        top: 680px !important;
      }
    }
    &.mW_15 {
      height: 355px !important;
    }
    &.mW_17 {
      left: 543px !important;
      height: 275px !important;
      top: 278px !important;
      margin: 0 !important;
      &.m17Sec {
        left: 544px !important;
        height: 115px !important;
        top: 512px !important;
      }
      &.m17Sec1 {
        left: 546px !important;
        height: 100px !important;
        top: 598px !important;
      }
    }
  }
}

.mw_women.mW_35-1 {
  left: 347px;
  width: 10px;
  border-left: 2px solid #bcbdbf;
  border-right: none !important;
  height: 1px;
  border-bottom: none !important;
  top: 82px;
}
.shift-arr1,
.shift-arr3,
.shift-arr2 {
  display: none !important;
}
@include breakpoints(medium) {
  .shift-arr1,
  .shift-arr3,
  .shift-arr2 {
    display: block !important;
  }
}
.m_women {
  @include breakpoints(medium) {
    .round-tab {
      pointer-events: none;
    }
  }
  @include breakpoints(small) {
    .shift-arr1 {
      display: block;
    }
    .round-tab {
      pointer-events: auto;
    }
  }

  .r_1 {
    width: 190px !important;
    @include breakpoints(xLarge) {
      width: 150px !important;
    }
    @include breakpoints(medium) {
      left: 0px !important;
    }
  }
  .r_3 {
    left: 210px !important;
    width: 155px !important;
    @include breakpoints(xLarge) {
      left: 170px !important;
    }
    &.right {
      left: unset !important;
      right: 210px !important;
      @include breakpoints(xLarge) {
        right: 170px !important;
      }
    }
  }
  .r_4 {
    left: 387px !important;
    width: 155px !important;
    @media only screen and (min-width: 1100px) and (max-width: 1300px) {
      width: 130px !important;
    }
    @include breakpoints(xLarge) {
      left: 340px !important;
    }
    &.right {
      left: unset !important;
      right: 387px !important;
      @include breakpoints(xLarge) {
        right: 340px !important;
      }
    }
  }

  .championship {
    left: 44% !important;
    width: 155px !important;
    @include breakpoints(xLarge) {
      left: 50% !important;
    }
    @include breakpoints(medium) {
      left: 560px !important;
    }
  }

  .secRd-left {
    .r_3 {
      left: 210px !important;
    }
    .r_4 {
      left: 375px !important;
    }
    .championship {
      left: 542px !important;
    }
  }
  .secRd-left1 {
    .r_4 {
      width: 135px !important;
      left: 401px !important;
    }
    .championship {
      left: 552px !important;
    }
  }
}

.women_champions_brackets {
  display: flex;
  @include breakpoints(medium) {
    flex-direction: column;
  }
}

.womens {
  &.pairs-wrap {
    position: absolute;
    width: 100%;
    top: 80px;
    @include breakpoints(medium) {
      top: 250px;
    }
    @include breakpoints(small) {
      top: 250px;
    }
  }
}
.navbar-dark .navbar-nav .nav-link {
  width: fit-content !important;
  padding: 5px 10px;
}

.slots-inner a {
  outline: none !important;
}
.active .tooltip-1,
.active .tooltip-1 span {
  font-weight: bold !important;
  font-family: $industry-bold !important;
}

.challenge-bracket-outer {
  overflow-y: hidden !important;
}

.bracket-error-message {
  display: flex;
  justify-content: center;
  padding: 50px;
  flex-direction: column;
  align-items: center;
  .message {
    padding: 20px;
    font-size: 22px;
    text-align: center;
    @include breakpoints(medium) {
      font-size: 18px;
    }
  }
  a {
    font-size: 14px;
    letter-spacing: 0.8px;
    text-decoration: underline;
    font-family: "industry-Medium";
    color: #14376f;
    margin-left: 14px;
    text-align: center;
  }
}
.mW_32,
.mW_33 {
  top: 5px !important;
}
