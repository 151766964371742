.leader-board-wrap {
  .Faqs {
    padding: 32px 17px 35px 30px;
    background-color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    .accordion__section_main {
      .accordion_chevron2 {
        margin-top: -45px !important;
      }
      .accordion_chevron {
        margin-top: -28px;
        .accordion__icon {
          background: url(https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/+.svg);
          background-repeat: no-repeat;
          background-position: 50%;
          .path {
            display: none !important;
          }
        }
        .rotate {
          background: url(https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/minus.png) !important;
          background-repeat: no-repeat;
          background-position: 50%;
          transform: none;
          transition: none;
          width: 5px;
        }
      }
      margin: 0;
      border: none;
      padding: 0;
      .accordion_heading {
        font-family: "MaisonNeue";
        font-size: 20px;
        width: 95%;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: #000;
      }
      .accordion_content {
        font-family: "MaisonNeue";
        font-size: 13px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.62;
        letter-spacing: normal;
        color: #616161;
        padding: 16px 8px 0 0;
        text-align: left;
      }
    }
    .header {
      padding: 0 0;
    }

    p {
      h3 {
        @include heading-faq;
        display: inline-block;
      }
      .leftfaq {
        font-family: $mirpuri-book;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        letter-spacing: 0.86px;
        color: #14376f;
        float: left;
      }
      .rightviewall {
        float: right;
        .btn-viewall {
          border: none;
          text-decoration: underline;
          background-color: transparent;
          outline: none;
          font-family: $industry-Medium;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          padding-right: 0px;
          line-height: normal;
          letter-spacing: 0.75px;
          color: #14376f;
        }
      }
    }

    .accordions {
      margin-top: 19px;

      .rotate {
        transform: none !important;
        background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/-.svg") !important;
        background-repeat: no-repeat;
        background-position: center;
      }

      .accordion__icon {
        background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/+.svg");
        background-repeat: no-repeat;
        background-position: center;

        path {
          display: none;
        }
      }

      .accordion__title {
        font-family: $mirpuri-book;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: #000000;
      }
      .accordion__content {
        background-color: white;
        overflow: hidden;
        transition: max-height 0.6s ease;
        border-right: none !important;
        border-bottom: none !important;
        border-left: none !important;

        .accordion__text {
          font-family: $mirpuri-book;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.62;
          letter-spacing: normal;
          color: #616161;
          padding: 16px 8px 0px 0px;
          text-align: left;
        }
      }

      .accordion {
        border-top: none !important;
        border-right: none !important;
        border-left: none !important;
        background-color: white;
        height: 46px;
        color: #444;
        cursor: pointer;
        padding: 18px 0;
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        transition: background-color 0.6s ease;
      }
    }
  }
  .leader-board-toplinks {
    @include breakpoints(large) {
      max-width: 100%;
      margin: 0 auto;
    }
    @include breakpoints(medium) {
      max-width: 100%;
    }
    .container {
      margin-top: 0;
    }
    ul {
      @include wrap-flx;
      padding: 0;
      margin: 0;
      justify-content: space-between;
      li {
        list-style: none;
        @include breakpoints(medium) {
          width: 50%;
        }
        @include breakpoints(small) {
          width: 100%;
          text-align: center;
          border-bottom: 1px solid $table-border;
          &:last-child {
            border-bottom: 0;
          }
        }
        .tab-links-leader {
          display: inline-flex;
          cursor: pointer;
          font-size: 15px;
          font-family: $mirpuri-book;
          padding: 15px 30px;
          color: $leader-tab;
          letter-spacing: 1px;
          @include breakpoints(medium) {
            padding: 15px 20px;
          }
          @include breakpoints(small) {
            width: 50%;
            display: inline-flex;
            justify-content: center;
            padding: 15px 0;
          }
          &.active {
            background-image: url("#{$imagePath}bgimg.jpg");
            font-weight: bold;
            color: $primary-color-blue;
          }
        }
        .premier-links {
          font-family: $industry-bold;
          font-size: 16px;
          letter-spacing: 1px;
          display: inline-flex;
          cursor: pointer;
          font-style: italic;
          padding: 8px 0px;
          line-height: 1.42;
          position: relative;
          margin-right: 40px;
          opacity: 0.45;
          color: #606060;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          @include breakpoints(medium) {
            font-size: 14px;
            margin-right: 25px;
          }
          @include breakpoints(small) {
            font-size: 13px;
            padding: 15px 0px;
          }
          .premier-icon {
            margin-right: 5px;
            width: 35px;
          }
          &:first-child {
            &:after {
              // content: "|";
              position: absolute;
              right: -30px;
              font-style: normal;
              color: #979797;
              @include breakpoints(medium) {
                right: -20px;
              }
            }
          }
          &:nth-child(2) {
            &:after {
              // content: "|";
              position: absolute;
              right: -30px;
              font-style: normal;
              color: #979797;
              @include breakpoints(medium) {
                right: -20px;
              }
            }
          }
          &:last-child {
            margin-right: 0;
          }
          &.active {
            color: $green;
            opacity: 1;
          }
        }
      }
    }
  }
  .Pointsbox {
    height: auto;
    padding: 17px 0px;
    text-align: center;
    border-radius: 5px;
    background-color: #f7f9fc;
    margin-bottom: 19px;
    .pointbox2 {
      padding: 0 !important;
    }
    a {
      font-size: 14px;
      color: #14376f;
      font-family: $industry-Medium;
    }
    .text {
      font-family: $mirpuri-book;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      color: #6d6d6d;
    }
    .number {
      font-family: $industry-Medium;
      font-size: 36px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.97;
      letter-spacing: 3px;
      color: #4d964c;
    }
    .red {
      color: #f4511e !important;
    }
    .green {
      color: #4d964c !important;
    }
  }
  .invite-body-outer {
    background-image: url("#{$imagePath}bgimg.jpg");
    .invite-body-inner {
      .invite-inner-left {
        padding: 0;
        background: none;
        border: none;
        box-shadow: none;
        .leaderboard-leftinner {
          @include box-style;
          padding: 30px 45px;
          @include breakpoints(medium){
            margin-bottom: 20px !important;
          }
          @include breakpoints(small) {
            padding: 15px 10px 15px 20px;
            margin-bottom: 20px !important;
          }
        }
      }
    }
  }
}

.table-scroll,
.table-scroll-points {
  .table {
    width: 100%;
    table,
    td {
      border-collapse: collapse;
    }
    thead {
      display: table;
      width: calc(100% - 0px);
      @include breakpoints(small) {
        width: calc(100% - 0px);
      }
    }
    th,
    td {
      font-size: 18px;
      font-family: $mirpuri-bold;
      letter-spacing: 0.4px;
      color: $text-grey;
      border-bottom: 1px solid $table-border;
      padding: 15px;
      word-break: break-all;
      width: 20%;
      vertical-align: middle;
      @include breakpoints(small) {
        font-size: 14px;
        padding: 13px 5px;
      }
      &:nth-child(1) {
        width: 10%;
        text-align: center;
        padding-left: 0;
        @include breakpoints(large) {
          width: 12%;
        }
        @include breakpoints(small) {
          width: 15%;
        }
      }
      &:nth-child(2) {
        width: 35%;
        @include breakpoints(small) {
          width: 35%;
        }
      }
      &:nth-child(3) {
        width: 35%;
        text-align: left;
        @include breakpoints(small) {
          width: 30%;
          padding-right: 10px;
        }
      }
      &:nth-child(4) {
        width: 25%;
        text-align: center;
        @include breakpoints(small) {
          width: 28%;
          padding-right: 10px;
        }
      }
    }
    th {
      &:nth-child(3) {
        padding-right: 15px;
      }
    }
    tbody {
      display: block;
      max-height: 700px;
      overflow-y: scroll;
      scrollbar-width: thin;
      scrollbar-color: $primary-color-blue #e9e9e9;
      @extend %scrollbarDesign1;
    }
    tr {
      display: table;
      width: 100%;
      box-sizing: border-box;
    }
    td {
      font-size: 16px;
      border-collapse: collapse;
      @include breakpoints(small) {
        font-size: 13px;
      }
    }
  }
}

.table-scroll-points {
  h1 {
    @include wrap-flx;
    justify-content:space-between;
    font-family: $mirpuri-bold;
    font-weight: 700;
    @include breakpoints(small) {
      font-size: 26px;
    }
    span {
      &:first-child {
        color: $primary-color-blue;
        font-size: 28px;
        @include breakpoints(small){
          order: 1;
        }
      }
      &.select-bkt{
        margin-left: 10px;
        @include breakpoints(small){
          order: 3;
          display: block;
          margin: 15px 0 0;
          width: 100%;
        }
        .form-control{
          font-size: 14px;
          padding-right: 30px;
          width: 200px;
          margin-right: 41px;
          @include breakpoints(small){
            width: 100%;
          }
        }
      }
      &:last-child {
        color: $green;
        font-size: 28px;
        @include breakpoints(small){
          order: 2;
        }
      }
    }
  }
  .table {
    th,
    td {
      padding: 10px;
      &:nth-child(1) {
        width: 79%;
        text-align: left;
        padding-left: 15px;
        font-family: $mirpuri-book;
        color: $black;
        font-weight: 700;
        @include breakpoints(small) {
          width: 70%;
          padding-left: 10px;
        }
      }
      &:nth-child(2) {
        width: 16%;
        text-align: right;
        padding-right: 30px;
        @include breakpoints(large) {
          width: 20%;
        }
        @include breakpoints(small) {
          width: 22%;
          padding-right: 20px;
        }
      }
      &:nth-child(3) {
        width: 0;
      }
    }
    th {
      &:nth-child(1) {
        padding-left: 0;
      }
      &:nth-child(2) {
        padding-right: 20px;
        @include breakpoints(small) {
          padding-right: 10px;
        }
      }
    }
    td {
      font-size: 14px;
      border-collapse: collapse;
    }
  }
  .greybg {
    background-color: $table-headbg;
    font-size: 18px !important;
    letter-spacing: 0.8px !important;
    font-family: $mirpuri-bold !important;
    @include breakpoints(small) {
      font-size: 14px !important;
      letter-spacing: 0px !important;
    }
  }
  .green {
    color: $green;
  }
  .red {
    color: $red;
  }
  .blue {
    color: $blue;
  }
  .points-total {
    font-size: 18px !important;
    letter-spacing: 0.8px !important;
    font-family: $mirpuri-bold !important;
    border-bottom: 0 !important;
    padding-bottom: 50px !important;
    @include breakpoints(small) {
      padding-bottom: 20px !important;
    }
  }
}

.leader-profileimg {
  padding: 0;
  display: flex;
  align-items: center;
  font-family: $mirpuri-book;
  color: #0d0d0d;
  .imgbox {
    width: 40px;
    height: 40px;
    @include border-radius(50%);
    @include box-shadow(0 0 4px 0 rgba(0, 0, 0, 0.11));
    background-color: #ccc;
    margin-right: 20px;
    overflow: hidden;
    @include breakpoints(small) {
      margin-right: 10px;
    }
  }
}
.leader-board-bktname{
  display: flex !important;
  flex-direction: column;
  align-items: flex-start !important;
  .bracket-name{
    font-weight: 700;
  }
}
.success-msg, .error-msg, .success-msg-left, .error-msg-left{
  font-family: "Montserrat-Medium";
  font-size: 13px;
  color: green;
  margin: 5px 0;
  text-align: center;
}
.success-msg-left, .error-msg-left{
  text-align: left;
}
.error-msg, .error-msg-left{
  color: red;
}
