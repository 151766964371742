/**======================================================================
=========================================================================
Template Name: Datta Able Admin Template
Author: Codedthemes
supports: https://codedthemes.support-hub.io/
File: style.css
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   Chat, authentication, Maintenance, Maps, Landingpage messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import "~bootstrap/dist/css/bootstrap";
@import "../assets/media-config.scss";
@import "../assets/variable.scss";
@import "../assets/mixin.scss";

//Navbar
@import "../../src/assets/navbar.scss";

//footer
@import "../../src/assets/footer.scss";
//font
//Circular Fonts
@font-face {
  font-family: "industry-bold";
  src: url(../assets/fonts/industry/Industry-Bold.ttf);
}
@font-face {
  font-family: "industry-Medium";
  src: url(../assets/fonts/industry/Industry-Medium.ttf);
}
@font-face {
  font-family: "MaisonNeue-Bold";
  src: url(../assets/fonts/mirpuri/mirpuri-bold.ttf);
}
@font-face {
  font-family: "MaisonNeue";
  src: url(../assets/fonts/mirpuri/mirpuri-book.ttf);
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url(../assets/fonts/monserat/Montserrat-Bold.ttf);
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url(../assets/fonts/monserat/Montserrat-Medium.ttf);
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url(../assets/fonts/monserat/Montserrat-Regular.ttf);
}

//carousel
@import "../../src/assets/carousel.scss";

//coustom style
@import "../../src/assets/custom-style.scss";
$roboto: 'Roboto Condensed', sans-serif;
$industry-bold: 'Roboto Condensed', sans-serif;
$industry-Medium: 'Roboto Condensed', sans-serif;
$mirpuri-bold:'Roboto Condensed', sans-serif;
$mirpuri-book: 'Roboto Condensed', sans-serif;
$Montserrat-Bold: 'Roboto Condensed', sans-serif;
$Montserrat-Medium: 'Roboto Condensed', sans-serif;
$Montserrat-Regular: 'Roboto Condensed', sans-serif;

// $industry-bold: "industry-bold";
// $industry-Medium: "industry-Medium";
// $mirpuri-bold: "MaisonNeue-Bold";
// $mirpuri-book: "MaisonNeue";
// $Montserrat-Bold: "Montserrat-Bold";
// $Montserrat-Medium: "Montserrat-Medium";
// $Montserrat-Regular: "Montserrat-Regular";

//to use it
body {
  font-family: $mirpuri-book, $mirpuri-bold;
}
.table tbody > tr > td,
.table tfoot > tr > td {
  border-top: none !important;
}

.loaderContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #0000009e;
  z-index: 1000000;
}
.lds-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  margin-left: -50px;
  margin-top: -50px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

//Modal
@import "../../src/assets/modal.scss";

//sighnup-form
@import "../../src/assets/sign-up.scss";

//Profile-form
@import "../../src/assets/profile.scss";

//About-us
@import "../../src/assets/about-us.scss";

//invite friend
@import "../../src/assets/invite-friend.scss";

//How to play
@import "../../src/assets/how-to-play.scss";

//brackets
@import "../../src/assets/bracket.scss";

//leader board
@import "../../src/assets/leader-board.scss";

//contact us
@import "../../src/assets/contact-us.scss";

//fill bracket
@import "../../src/assets/fill-bracket.scss";

//fill bracket
@import "../../src/assets/verify-mail.scss";
@import "../../src/assets/bracket-groups.scss";

//accordion
@import "../../src/assets/accordion.scss";

//share button
@import "../../src/assets/shareButton.scss";

.col-md-6 {
  max-width: 49.5% !important;
  @media screen and (max-width: 767px) {
    max-width: 100% !important;
  }
}
