.wrapper-share {
    position: relative;

    button {
        border: none;
        background: transparent;
    }

    .share-box {
        position: absolute;
        right: 10px;
        bottom: 35px;
        background: white;
        padding: 20px;
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        @media screen and (max-width: 420px) {
            &.v2 {
                right: -50px;
            }
          }
    }
}