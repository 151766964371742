.profilemd {
  background-color: #eeeeee;
}
.contactus {
  background-color: #eeeeee;

  .place-b {
    @media screen and (max-width: 100px) {
      text-align: center;
      justify-content: center;
      display: flex;
    }
    @media screen and (max-width: 1039px) {
      padding: 20px 20px 0;
      justify-content: center;
      display: flex;
      .place-lo {
        margin: 0px !important;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 20px 20px 0;
      justify-content: center;
      display: flex;
      .place-lo {
        margin: 0px !important;
        max-width: 355px;
      }
    }
  }
  .sighnup-box {
    height: auto;
    max-width: 100% !important;
    width: 100%;
  }

  .frombtn {
    margin-top: 0px !important;
  }
  .firstrow {
    height: 108px;
    @media screen and (max-width: 767px) {
      height: auto;
    }
  }

  @media screen and (max-width: 767px) {
    .sighnup-box .col-md-5 {
      display: block;
    }
  }

  .sighnuppad {
    justify-content: center;
    display: flex;
    padding: 40px 7.5vw;
    @media screen and (max-width: 1200px) {
      padding: 40px 10px;
    }
    @media screen and (max-width: 767px) {
      margin: -40px -5px !important;
    }

    .fname {
      margin-bottom: 31px;
    }
    .lname {
      margin-bottom: 31px;
    }
    .form-group {
      margin-bottom: 31px;
      width: 100%;
    }
    .textarea {
      height: 118px !important;
      @media screen and (max-width: 767px) {
        height: 240px !important;
      }
    }
    .form-control {
      height: 50px;
      border-radius: 2px;
      border: solid 1px #d1d1d1;
      background-color: var(--white);
      font-family: "MaisonNeue";
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: black;
    }
    .form-label {
      font-family: "MaisonNeue";
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      color: #333333;
      text-align: left;
      width: 100%;
    }

    .sighnup-box {
      .col-md-7 {
        @media screen and (max-width: 767px) {
          padding: 0 5px;
        }
      }

      border-radius: 4px;
      box-shadow: 0 12px 24px 0 rgba(10, 47, 106, 0.04);
      border: solid 1px #dddddd;
      background-color: var(--white);
      @media screen and (max-width: 767px) {
        padding: 0;
        margin-bottom: 20px;
      }

      .sighnup-form {
        background: white;
        padding: 36px 10px 29px 10px;
        @media screen and (max-width: 767px) {
          padding: 36px 0px 29px 0px;
        }
      }

      .header-title {
        padding: 0 15px;
        font-family: $mirpuri-book;
        font-size: 28px;
        margin-bottom: 21px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: 0.7px;
        color: #14376f;
      }
    }
  }

  .national-box {
    max-width: 403px;
    width: 100%;
    height: 487px;
    @media screen and (max-width: 300px) {
      height: 350px !important;
      padding: 28px 0px 28px 10px !important;
      margin: 0px 15px 30px !important;
    }
    @media screen and (max-width: 767px) {
      height: 250px;
      padding: 28px 0px 28px 30px;
      margin: 0px 15px 30px;
    }
    margin: 45px 48px 38px 10px;
    padding: 37px 5px 253px 40px;
    border-radius: 3px;
    border: solid 1px #dddddd;
    background-color: #f6f7fa;
    .title {
      font-family: $mirpuri-book;
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.41;
      letter-spacing: normal;
      color: #14376f;
    }
    .contactadd {
      width: 100%;
      margin-top: 35px;
      .location {
        img {
          display: inline-block;
          margin-right: 10px;
          padding-bottom: 39px;
        }
        p {
          width: 80%;
          display: inline-block;
          font-family: $mirpuri-book;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.53;
          letter-spacing: 0.54px;
          color: #14376f;
        }
      }
      .mail {
        img {
          display: inline-block;
          margin-right: 10px;
        }
        p {
          width: 80%;
          display: inline-block;
          font-family: $mirpuri-book;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.53;
          letter-spacing: 0.54px;
          color: #14376f;
        }
      }
    }
  }

  .fawrow {
    display: none;
    @media screen and (max-width: 700px) {
      background-color: rgb(238, 238, 238);
      margin-bottom: 50px;
      margin-left: -15px;
      margin-right: -15px;
      display: block;
    }
  }

  .img-box1 {
    display: none;
    @media screen and (max-width: 700px) {
      display: block;
      position: inherit;
      max-width: 100%;
      margin-top: 20px;
      margin-bottom: 31px;
      margin-left: 0px;
    }
    max-width: 338px;
    width: 100%;
    margin-left: 50px;
    position: absolute;
    .img-box {
      height: 290px;
      .img {
        background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/invite-right-img.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-attachment: inherit;
        display: inline-flex;
      }
    }
  }
}
.Aboutus {
  @media screen and (max-width: 700px) {
    padding: 20px 23px 31px 23px;
  }
  background-color: white;
  height: auto;

  p {
    width: 100%;
    height: auto;
    font-family: $mirpuri-book;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: 0.5px;
    color: #333333;
  }
  .p-green-bold {
    font-weight: bold;
    color: #4d964c;
    font-family: $mirpuri-book;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: 0.5px;
  }
  .p-black-bold {
    font-family: $mirpuri-book;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.73;
    letter-spacing: 0.54px;
    color: #333333;
  }
}

.img-box {
  background-color: white;
  padding: 20px 19px;
  text-align: center;
  .img {
    max-width: 300px;
    width: 100%;
    height: 250px;
    background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/new/placeholder2.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: inherit;
    display: inline-flex;
  }
}

.Faqs {
  padding: 32px 17px 35px 30px;
  background-color: white;
  margin-top: 20px;
  margin-bottom: 20px;
  .accordion__section_main {
    .accordion_chevron2 {
      margin-top: -68px !important;
      @media screen and (max-width: 1039px) {
        margin-top: -38px !important;
      }
      @media screen and (max-width: 500px) {
        margin-top: -42px !important;
      }
      @media screen and (max-width: 400px) {
        margin-top: -68px !important;
      }
    }

    .accordion_chevron {
      margin-top: -40px;
      @media screen and (max-width: 350px) {
        margin-top: -65px !important;
      }
      .accordion__icon {
        background: url(https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/+.svg);
        background-repeat: no-repeat;
        background-position: 50%;
        .path {
          display: none !important;
        }
      }
      .rotate {
        background: url(https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/minus.png) !important;
        background-repeat: no-repeat;
        background-position: 50%;
        transform: none;
        transition: none;
        width: 5px;
      }
    }
    margin: 0;
    border: none;
    padding: 0;
    .accordion_heading {
      margin: 2px 371px 10px 0;
      font-family: "MaisonNeue";
      font-size: 20px;
      @media screen and (max-width: 300px) {
        font-size: 18px;
      }
      width: 95%;
      @media screen and (max-width: 767px) {
        width: 90%;
      }
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      color: #000;
    }
    .accordion_content {
      font-family: "MaisonNeue";
      font-size: 13px;
      font-weight: 400;
      margin: 0;
      font-stretch: normal;
      height: 250px;
      @media screen and (max-width: 300px) {
        font-size: 11px;
      }
      font-style: normal;
      line-height: 1.62;
      letter-spacing: normal;
      color: #616161;
      padding: 16px 8px 0 0;
      text-align: left;
    }
  }
  .header {
    padding: 0 0;
  }

  p {
    h3 {
      @include heading-faq;
      display: inline-block;
    }
    .leftfaq {
      font-family: $mirpuri-book;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.08;
      letter-spacing: 0.86px;
      color: #14376f;
      float: left;
    }
    .rightviewall {
      float: right;
      .btn-viewall {
        border: none;
        text-decoration: underline;
        background-color: transparent;
        outline: none;
        font-family: $industry-Medium;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        padding-right: 0px;
        line-height: normal;
        letter-spacing: 0.75px;
        color: #14376f;
      }
    }
  }

  .accordions {
    margin-top: 19px;

    .rotate {
      transform: none !important;
      background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/-.svg") !important;
      background-repeat: no-repeat;
      background-position: center;
    }

    .accordion__icon {
      background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/+.svg");
      background-repeat: no-repeat;
      background-position: center;

      path {
        display: none;
      }
    }

    .accordion__title {
      font-family: $mirpuri-book;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      color: #000000;
    }
    .accordion__content {
      background-color: white;
      overflow: hidden;
      transition: max-height 0.6s ease;
      border-right: none !important;
      border-bottom: none !important;
      border-left: none !important;

      .accordion__text {
        font-family: $mirpuri-book;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.62;
        letter-spacing: normal;
        color: #616161;
        padding: 16px 8px 0px 0px;
        text-align: left;
      }
    }

    .accordion {
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
      background-color: white;
      height: 46px;
      color: #444;
      cursor: pointer;
      padding: 18px 0;
      display: flex;
      align-items: center;
      border: none;
      outline: none;
      transition: background-color 0.6s ease;
    }
  }
}
