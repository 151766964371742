.invite-friend-wrap {
  .timer-banner-inner {
    @media (max-width: 767px) {
      max-width: 90% !important;
      .timer-style {
        width: 230px !important;
        text-align: center !important;
        .timer-clock {
          font-size: 26px !important;
          .separator {
            font-size: 26px !important;
            margin: 0 0 0 10px !important;
          }
        }
      }
    }
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 5px !important;
  }
  .modal {
    background-color: #00000029;
  }
  .invitefrendpopover {
    .modal-body {
      position: relative;
      flex: 1 1 auto;
      padding: 20px 28px 5px 28px !important;
    }
    .modal-dialog {
      right: auto;
      display: flex;
      left: 0;
      justify-content: center;
      max-width: 450px;
      width: 100%;
      padding-top: 15%;
      padding-bottom: 30px;
    }
    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      @include breakpoints(small) {
        width: 79%;
      }
      min-height: 200px !important;
      height: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border: none;
      border-radius: 5px;
      outline: 0;
    }
    .modal-header {
      padding: 10px 10px 30px 10px;
      min-height: 115px;
      margin-left: -0.02px;
      align-items: center;
      background-color: #0e4294;
    }
    .camera-box {
      width: 100%;
      text-align: center;
    }

    .modal-title {
      margin-bottom: 0;
      color: white;
      font-family: "MaisonNeue-Bold";
      line-height: 1.5;
      text-align: center;
      width: 100%;
    }
    .text-center {
      text-align: center !important;
      font-family: $mirpuri-book;
      display: flex;
      justify-content: center;
    }
    .nav-link {
      display: block;
      padding: 0.5rem 1rem;
      color: white;
      background-color: #0e4294;
    }
    .nav-link:hover,
    .nav-link:focus {
      text-decoration: underline;
    }
  }

  position: relative;
  @include breakpoints(large) {
    padding-top: 8%;
  }
  @media screen and (max-width:1000px) {
    padding-top: 55px;
  }
  @include breakpoints(medium) {
    padding-top: 30px;
  }
  .leaderord {
    .lead-img {
      max-width: 1180px;
      margin: 0 auto;
    }
  }
  .invite-body-outer {
    background-color: #eeeeee;
    .container {
      margin-top: 0;
    }
    .invite-body-inner {
      .invite-inner-left {
        width: 810px;
        padding: 55px 60px 40px;
        &.subPage {
          max-width: 910px;
        }
        @include box-style;
        @include breakpoints(large) {
          width: 68%;
        }
        @include breakpoints(medium) {
          padding: 55px 20px 40px;
          width: 100%;
          margin-bottom: 20px;
        }
        @include breakpoints(small) {
          padding: 20px 0 20px;
        }
        .invite-icon {
          text-align: center;
          margin-bottom: 20px;
        }
        h1 {
          @include heading1;
          text-align: center;
          margin-bottom: 20px;
        }
        .invite-text {
          @include invite-text;
          margin-bottom: 50px;
          text-align: center;
          @include breakpoints(small) {
            padding: 0 15px;
            margin-bottom: 30px;
          }
        }
        .invite-message-box {
          .fromgrid {
            @include breakpoints(small) {
              .col-lg-3 {
                width: 20%;
              }
              .col-lg-9 {
                width: 80%;
              }
            }
          }
          .togrid {
            // @include breakpoints(small) {
            //   .col-lg-3 {
            //     width: 20%;
            //   }
            //   .col-lg-9 {
            //     width: 80%;
            //   }
            // }
          }
          padding: 0;
          @include invite-text;
          p {
            word-wrap: break-word;
            margin-bottom: 20px;
            font-size: 12px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

// left right wraps
.invite-body-outer {
  background-color: #eeeeee;
  .container {
    margin-top: 0;
  }
  .invite-body-inner {
    margin: 21px auto 50px;
    display: flex;
    justify-content: space-between;
    column-gap: 15px;
    @include breakpoints(large) {
      max-width: 100%;
    }
    @include breakpoints(medium) {
      flex-wrap: wrap;
      margin: 20px auto;
    }
    @include breakpoints(small) {
      flex-wrap: wrap;
      margin: 0;
      max-width: 100%;
      padding: 20px 0;
    }
  }
  .invite-inner-left {
    width: 810px;
    padding: 55px 60px 40px;
    @include box-style;
    @include breakpoints(large) {
      width: 68%;
    }
    @include breakpoints(medium) {
      padding: 55px 20px 40px;
      width: 100%;
      margin-bottom: 20px;
    }
    @include breakpoints(small) {
      padding: 20px 0 20px;
    }
  }
  .invite-inner-right {
    @media screen and (max-width: 380px) {
      .place-b {
        padding: 10px 20px !important;
        .place-lo {
          margin: 0px !important;
        }
      }
    }
    width: 340px;
    &.subPage {
      max-width: 400px
    }
    @include breakpoints(large) {
      width: 30%;
    }
    @include breakpoints(medium) {
      width: 100%;
    }
    .invite-right-top {
      @include box-style;
      padding: 15px;
      margin-bottom: 20px;
      text-align: center;
      img {
        width: 100%;
        @include breakpoints(medium) {
          width: 50%;
        }
        @include breakpoints(small) {
          width: 100%;
        }
      }
    }
    .invite-faq-right {
      @include box-style;
      padding: 0;
    }
    .faqs-invite {
      h3 {
        @include heading-faq;
        margin-bottom: 15px;
      }
      .accordions {
        .accordion {
          border: 0 !important;
          padding: 0 15px 0 0;
          height: auto;
          position: relative;
        }
        .accordion__content {
          border: 0;
        }
        .accordion__text {
          font-size: 13px;
          line-height: 1.6;
          padding: 20px 0 5px;
        }
        .accordion__title {
          font-size: 20px;
          font-family: $mirpuri-book;
          color: #000000;
          font-weight: 500;
          @include breakpoints(medium) {
            font-size: 16px;
          }
        }
        .accordion__icon {
          position: absolute;
          top: 5px;
          right: 0;
        }
        .accordion__section {
          margin-bottom: 20px;
        }
      }
    }
  }
}

// timer banner
.timer-banner-wrap {
  background: rgb(77, 150, 76);
  background: linear-gradient(
    90deg,
    rgba(77, 150, 76, 1) 0%,
    rgba(10, 47, 106, 1) 100%
  );
  padding: 25px 0;
  @include breakpoints(small) {
    padding: 20px 0;
  }
  .timer-banner-inner {
    display: flex;
    justify-content: space-between;
    @include breakpoints(large) {
      max-width: 95%;
      margin: 0 auto;
      flex-wrap: wrap;
    }
    .tm-banner-logo {
      position: relative;
      @include breakpoints(small) {
        img {
          width: 80px;
        }
      }
    }
    .tm-banner-timer {
      position: relative;
      @include breakpoints(small) {
        top: -10px;
      }
    }
    .tm-banner-text {
      position: relative;
      font-family: $industry-bold;
      color: #ffffff;
      font-size: 28px;
      letter-spacing: 1.8px;
      text-align: right;
      line-height: 1.3;
      font-style: italic;
      font-weight: 700;
      @include breakpoints(medium) {
        width: 100%;
        text-align: center;
        margin-top: 20px;
      }
      @include breakpoints(small) {
        font-size: 22px;
        margin-top: 10px;
      }
    }
  }
}
// timer component css
.timer-style {
  display: flex;
  width: 380px;
  justify-content: space-between;
  @include breakpoints(small) {
    width: 250px;
  }
  .timer-clock {
    font-size: 40px;
    letter-spacing: 1.3px;
    color: #fff;
    font-family: $industry-bold;
    width: 16%;
    font-weight: 700;
    @include breakpoints(small) {
      font-size: 34px;
    }
    @media screen and (max-width: 300px) {
      width: 25% !important;
      font-size: 24px !important;
    }
    .separator {
      font-size: 32px;
      font-family: $industry-Medium;
      position: relative;
      margin: 0 0 0 30px;
      top: -3px;
      @media screen and (max-width: 800px) {
        margin: 0 0 0 25px;
      }
      @include breakpoints(small) {
        font-size: 34px;
        margin: 0 0 0 10px;
      }
      @media screen and (max-width: 300px) {
        font-size: 24px !important;
        margin: 0 0 0 10px !important;
      }
    }
    .timer-name {
      font-size: 11px;
      font-family: $industry-Medium;
      color: #fff;
      text-transform: uppercase;
      @include breakpoints(small) {
        font-size: 9px;
      }
    }
  }
}

.input-invite {
  @include border-radius(4px);
  border: 1px solid #959595;
  height: 45px;
  padding: 0 15px;
  width: 100%;
  @include box-sizing(border-box);
}
.textarea-invite {
  height: 100px;
}
.invite-submit {
  width: 235px;
  height: 46px;
  background-color: #14376f;
  border: none;
  font-size: 16px;
  font-family: $industry-bold;
  text-align: center;
  letter-spacing: 1px;
  color: white;
}
.react-tagsinput {
  .react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 70%;
  }
  .react-tagsinput-tag {
    background-color: white;
    border-radius: 2px;
    border: 1px solid #bdbdbd;
    color: #434146;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
  }
  a:hover {
    color: #0056b3;
    text-decoration: none;
  }
  a {
    color: #14376f;
    text-decoration: none;
  }
}
.react-tagsinput--focused {
  border-color: #14376f !important;
}
