.fillbracket {
  .timer-banner-wrap {
    padding-right: 5%;
    padding-left: 5%;
    @media screen and (max-width: 1050px) {
      padding-right: 0%;
      padding-left: 0%;
    }
    @media screen and (max-width: 800px) {
      padding-right: 5%;
      padding-left: 5%;
    }
    @media screen and (max-width: 700px) {
      padding: 20px 0;
    }
  }

  .startbtn {
    margin-top: 41px;
    width: 100%;
    .bluebtn {
      border-radius: 2px;
      max-width: 256px;
      width: 100%;
      background-color: #14376f;
      font-family: $industry-Medium;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      height: 46px;
      width: 100%;
      font-style: normal;
      line-height: 2.14;
      letter-spacing: 1px;
      text-align: center;
      color: var(--white);
    }
  }

  .invite-body-outer {
    // background-color: white;
    // background: url(https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/bgimg.jpg);
  }
  .invite-body-outer>.container-fluid{
    background-color: white;
  }
  

  .container {
    max-width: 1180px;
  }
  .tab-content{
    padding: 0 6.3%;
    background: url(https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/bgimg.jpg);
    .tab-pane{
      max-width: 1180px;
      margin: 0 auto;
    }
    @media screen and (max-width:1100px){
      padding: 0
    }
  }

  .nav-tabs {
    border: none !important;
    // background-color: white;
    // padding: 0 6.3%;
    max-width: 1180px;
    margin: 0 auto;
    @media screen and (max-width:1100px){
      padding: 0 20px;
    }
    @media screen and (max-width: 700px) {
      padding: 0;
    }
    .nav-link {
      max-width: 196px;
      width: 100%;
      @media screen and (max-width: 700px) {
        max-width: 186px;
      }
      background-color: transparent;
      border-color: none !important;
      border: none !important;
      height: 58px;
      font-family: $mirpuri-book;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      padding-top: 20px;
      text-align: center;
      letter-spacing: 1px;
      color: #454545;
    }

    .nav-link.active {
      max-width: 196px;
      width: 100%;
      @media screen and (max-width: 700px) {
        max-width: 186px;
      }
      background: url(https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/bgimg.jpg);
      height: 58px;
      font-family: $mirpuri-book;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center;
      color: #14376f;
    }
  }

  .createbracket {
    width: 100%;
    // text-align: center;
    padding-top: 107px;
    // justify-content: center;
    // display: flex;
    background: url(https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/bgimg.jpg);
    padding-bottom: 70px;
    @media screen and (max-width: 700px) {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 40px;
      padding-bottom: 41px;
    }
    .inner-body {
      max-width: 720px;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      @media screen and (max-width: 767px){
        margin-top: 30px;
      }
      h1 {
        margin-bottom: 23px;
        font-family: $mirpuri-book;
        font-size: 38px;
        font-weight: 700;
        @media screen and (max-width: 700px) {
          font-size: 28px;
        }
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.03;
        letter-spacing: normal;
        text-align: center;
        color: #14376f;
      }
      h2 {
        max-width: 660px;
        width: 100%;
        margin-bottom: 10px;
        font-family: $mirpuri-book;
        font-size: 38px;
        @media screen and (max-width: 700px) {
          font-size: 26px;
        }
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.03;
        letter-spacing: normal;
        text-align: center;
        color: #14376f;
      }
      h3 {
        font-family: $mirpuri-book;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: normal;
        text-align: center;
        color: #14376f;
        margin-bottom: 24px;
      }
      h4 {
        max-width: 660px;
        width: 100%;
        font-family: $mirpuri-book;
        font-size: 24px;
        @media screen and (max-width: 700px) {
          font-size: 16px;
        }
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        text-align: center;
        color: #14376f;
        margin-bottom: 24px;
      }
      p {
        margin-bottom: 50px;
        font-family: $mirpuri-book;
        font-size: 16px;
        @media screen and (max-width: 700px) {
          font-size: 14px;
        }
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: center;
        color: #363636;
      }
      .gendertornament {
        .button1 {
          display: inline-block;
          width: 270px;
          height: 76px;
          margin-right: 20px;
          @media screen and (max-width: 700px) {
            margin-right: 0px;
            margin-bottom: 19px;
          }
          button {
            font-family: $industry-Medium;
            font-size: 16px;
            width: 100%;
            font-weight: bold;
            font-stretch: normal;
            background-color: white;
            font-style: italic;
            height: 100%;
            line-height: normal;
            padding: 0;
            border: none;
            letter-spacing: 1px;
            color: #363636;

            img {
              padding-bottom: 5px;
            }
          }
          .active {
            border: solid 2px #14376f;
            background-color: #f7faff;
          }
          button:active {
            border: solid 2px #14376f;
            background-color: #f7faff;
          }
          button:hover {
            border: solid 2px #14376f;
            background-color: #f7faff;
          }
          &.disabled {
            position: relative;
            opacity: 0.7;
            &::after {
              content:'';
              position: absolute;
              left: 0;
              right: 0;
              top:0;
              bottom: 0;

            }
          }
        }
        .button2 {
          display: inline-block;
          width: 270px;
          height: 76px;

          .active {
            border: solid 2px #14376f;
            background-color: #f7faff;
          }

          button {
            font-family: $industry-Medium;
            font-size: 16px;
            width: 100%;
            font-weight: bold;
            background-color: white;
            font-stretch: normal;
            padding: 0;
            font-style: italic;
            height: 100%;
            line-height: normal;
            border: none;
            letter-spacing: 1px;
            color: #363636;

            img {
              padding-bottom: 5px;
            }
          }
          button:active {
            border: solid 2px #14376f;
            background-color: #f7faff;
          }
          button:hover {
            border: solid 2px #14376f;
            background-color: #f7faff;
          }
          &.disabled {
            position: relative;
            opacity: 0.7;
            &::after {
              content:'';
              position: absolute;
              left: 0;
              right: 0;
              top:0;
              bottom: 0;

            }
          }
        }
      }
    }
  }

  .leaderboard {
    width: 100%;
    text-align: center;
    justify-content: center;
    display: flex;
    padding-top: 107px;
    background: url(https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/bgimg.jpg);
    padding-bottom: 70px;
  }

  .fillbracet {
    max-width: 660px;
    width: 100%;

    .timer-style {
      display: flex;
      padding-top: 22px;
      @media screen and (max-width: 700px) {
        padding-right: 10px;
        padding-left: 10px;
      }
      width: 100%;
      justify-content: space-between;

      .timer-clock {
        font-family: $industry-Medium;
        font-size: 46px;
        @media screen and (max-width: 700px) {
          font-size: 36px;
        }
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.77px;
        text-align: center;
        color: #4d964c;
        border-right: solid 1px #979797;

        .timer-name {
          font-family: $industry-Medium;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.46px;
          text-align: center;
          @media screen and (max-width: 700px) {
            text-align: left;
            padding-left: 16px;
            font-size: 11px;
          }
          color: #8d8d8d;
        }
      }
      .timer-clock:last-of-type {
        border: none !important;
      }
    }

    .countdowntimer {
      max-width: 660px;
      width: 100%;
      height: 115px;
      background-color: var(--white);
    }

    p {
      margin-top: 34px;
      margin-bottom: 38px;
      font-family: $mirpuri-book;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      text-align: center;
      color: #363636;
    }

    .emailerremainder {
      max-width: 660px;
      width: 100%;
      height: 329px;
      text-align: center;
      padding-top: 45px;
      background-color: white;

      h5 {
        margin-top: 27px;
        margin-bottom: 18px;
        font-family: $mirpuri-book;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: normal;
        text-align: center;
        color: #14376f;
      }
      p {
        margin-top: 18px;
        margin-bottom: 33px;
        font-family: $mirpuri-book;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        text-align: center;
        color: #8d8d8d;
      }

      .remidbutton {
        width: 100%;
        text-align: center;
        margin-bottom: 59px;
        button {
          border-radius: 4px;
          border: solid 1px #14376f;
          max-width: 148px;
          height: 40px;
          font-family: $industry-Medium;
          font-size: 14px;
          font-weight: 500;
          width: 100%;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.14;
          letter-spacing: 1px;
          text-align: center;
          color: #14376f;
        }
      }
    }
  }

  .menswomenpremier {
    display: none;
    @media screen and (max-width: 700px) {
      display: block;
    }
    .gendertornament {
      .btn.active {
        width: 100%;
        background-image: none;
        outline: 0;
        padding: 0;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        font-family: $industry-Medium;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: 0.81px;
        color: #4d964c;

        img {
          width: 20%;
        }
      }
      .btn {
        width: 100%;
        padding: 0;
        font-family: $industry-Medium;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: 0.81px;
        color: #bababa;

        img {
          width: 20%;
        }
      }
      .button1 {
        display: inline-block;
        width: 47%;
        margin: 20px 0;
        border-right: solid 1px #979797;
      }
      .button2 {
        display: inline-block;
        width: 50%;
        margin: 20px 0;
      }
    }
  }
}
