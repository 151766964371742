html {
  font-size: 16px;
}
.sighnuppad {
  justify-content: center;
  display: flex;
  .password_b {
    position: relative;
    .password__show {
      position: absolute;
      cursor: pointer;
      right: 20px;
      @media screen and (max-width: 700px) {
        right: 10px;
      }
      bottom: 8px;
    }
  }
  .form-dob-icon {
    position: relative;
    .claendaricon {
      // width: 100%;
      // float: right;
      position: absolute;
      left: 83%;
      top: 30px;
      @media screen and (max-width: 1370px) {
        // bottom: 25%;
        left: 85%;
      }
      @media screen and (max-width: 1030px) {
        // bottom: 28% !important;
        left: 80%;
      }
      @media screen and (max-width: 800px) {
        // bottom: 24% !important;
        left: 78%;
      }
      @media screen and (max-width: 750px) {
        // bottom: 24% !important;
        left: 93%;
      }
      @media screen and (max-width: 700px) {
        // bottom: 28% !important;
        left: 88%;
      }
      img {
        width: 22px;
        @media screen and (max-width: 1370px) {
          width: 25px;
        }
        @media screen and (max-width: 767px) {
          width: 25px;
        }
      }
    }
  }

  .form-dob {
    //max-width:476px;
    width: 100% !important;
  }

  padding: 50px;
  padding-left: 12.5vw;
  padding-right: 12.5vw;
  @media screen and (max-width: 12000px) {
    padding-left: 5.5vw;
    padding-right: 5.5vw;
  }
  @media screen and (max-width: 700px) {
    padding: 0 !important;
    margin: 0 -15px;
  }
  @media screen and (max-width: 1000px) {
    padding: 10px;
  }

  .sighnup-box {
    box-shadow: 0 12px 24px 0 rgba(10, 47, 106, 0.04);
    background-color: var(--white);
    @media screen and (max-width: 700px) {
      padding: 0 15px;
    }
  }

  .pad-bot {
    margin-bottom: 30px;
    @media screen and (max-width: 700px) {
      margin-bottom: 0px;
    }
  }

  .profilepic {
    @media screen and (max-width: 700px) {
      text-align: center;
      margin-bottom: 25px;
      margin-bottom: 25px;
    }
    .upimg {
      width: 121px;
      height: 121px;
      // background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/user-default-picture.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-attachment: inherit;
      margin-bottom: 5px;
      margin-top: 5px;
      margin-left: -20px;
      border: 0px solid white;
      border-radius: 60px;
      @media screen and (max-width: 700px) {
        display: inline-table;
      }
    }
    .uploadImgLink {
      text-decoration: none;
      color: #14376f;
      cursor: pointer;
      font-size: 12px;

      margin-top: 10px;

      // @media screen and (max-width: 1300px) {
      //   left: -4px;
      // }
      // @media screen and (max-width: 1200px) {
      //   left: 0;
      // }
      @media screen and (max-width: 767px) {
       span {
        padding-right: 15px;
        }
      }
      @media screen and (min-width: 768px) {
        span {
          padding-left: 5px;
        }
      }
      position: relative;
    }
    .uploadimg {
      width: 80%;
      @media screen and (max-width: 700px) {
        width: 100%;
      }
      .uploadbtn {
        font-family: $mirpuri-book;
        font-size: 12px;
        font-weight: normal;
        background: transparent;
        padding-top: 10px;
        border: none;
        outline: none;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #14376f;
      }
    }
  }

  /* appearance for checked radiobutton */
  input[type="radio"]:checked {
    background-color: #14376f !important;
    border: 1px solid #14376f !important;
  }

  .err-paragraph {
    font-size: 14px;
    color: red;
    width: fit-content;
    @media screen and (max-width: 400px) {
      width: 100%;
    }
  }
  .termscond {
    width: 100%;
    text-align: left;
    margin-left: -15px;
    p {
      font-family: $mirpuri-book;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.58;
      letter-spacing: 0.43px;
      color: #777777;
      display: inline-flex;
      align-items: center;
      a {
        color: #14376f;
      }
    }
  }

  .ncremail {
    text-align: left;
    margin-left: -15px;
    margin-top: 0;
    width: 100%;
    display: contents;
    padding-left: 20px;
    p {
      font-family: "MaisonNeue";
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.58;
      letter-spacing: 0.43px;
      color: #777777;
      @media screen and (max-width: 768px) {
        margin-bottom: 0;
      }
      padding-left: 15px;
      display: contents;
      align-items: center;

      a {
        color: #0056b3;
        display: contents;
      }
    }
  }
  input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
    width: 13px;
    height: 13px;
    margin-right: 6px;
    padding-top: 0;
    position: relative;
    top: 3px;
  }

  .form-check {
    label {
      font-family: $mirpuri-book;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2d2d2d;
      margin-right: 34px;
    }
    .form-check-input {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  }

  .form-label {
    font-family: $mirpuri-book;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #333333;
    text-align: left;
    width: 100%;
  }

  .mb-3 {
    margin-bottom: 25px !important;
    margin-top: 0px;
    @media screen and (max-width: 700px) {
      margin-bottom: 20px !important;
      margin-top: 0px;
    }
  }
  .form-group {
    margin-bottom: 15px;
    width: 100%;
    @media screen and (max-width: 700px) {
      margin-bottom: 15px;
    }
  }

  .form-control {
    height: 42px;
    border-radius: 2px;
    border: solid 1px #d1d1d1;
    background-color: var(--white);
    font-family: $mirpuri-book;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8d8d8d;
  }

  .frombtn {
    max-width: 198px;
    @media screen and (max-width: 700px) {
      max-width: 100%;
    }
    margin-top: 0;

    width: 100%;
    .bluebtn {
      border-radius: 2px;
      background-color: #14376f;
      font-family: $industry-Medium;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      height: 46px;
      width: 100%;
      font-style: normal;
      line-height: 2.14;
      letter-spacing: 1px;
      text-align: center;
      color: var(--white);
    }
  }
  .bluebtn {
    border-radius: 2px;
    background-color: #14376f;
    font-family: $mirpuri-book;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    height: 46px;
    font-style: normal;
    line-height: 2.14;
    letter-spacing: 1px;
    text-align: center;
    color: var(--white);
  }
  .react-calendar{
    height: auto;
    position: absolute;
    z-index: 2;
  }
}
.contactus {
  .sighnup-box {
    @media screen and (max-width: 700px) {
      margin-top: 10px;
    }
  }
}
.sighnup-box {
  height: auto;
  max-width: 1079px;
  width: 100%;
  .col-md-5 {
    margin-left: -15px;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .titele {
    font-family: $mirpuri-book;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: left;
    @media screen and (max-width: 700px) {
      text-align: center;
    }
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.7px;
    color: #000000;
  }
  .sighnup-img {
    max-width: 420px;
    width: 100%;
    .img {
      width: 100%;
      height: 760px;
      // background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/signup-img-2.jpg");
      background: url("../assets/images/signup-bg-latest.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-attachment: inherit;
    }
  }

  .sighnup-form {
    background: white;
    padding: 36px 30px 29px 30px;
    @media screen and (max-width: 780px) {
      padding: 36px 0px 29px 0px;
    }
    .col-md-8 {
      padding-right: 0;
      @media screen and (max-width: 700px) {
        padding-right: 0;
        padding-left: 0;
      }
    }
    .react-datepicker-wrapper {
      width: 100%;
    }
    .react-datepicker-popper[data-placement^="top"]
      .react-datepicker__triangle {
      margin-left: -15px;
    }
  }
}
::-webkit-input-placeholder {
  /* Edge */
  font-family: $mirpuri-book;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8d8d8d;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-family: $mirpuri-book;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8d8d8d;
}
:-ms-input-placeholder {
  /* Internet Explorer */
  font-family: $mirpuri-book;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8d8d8d;
}

::placeholder {
  font-family: $mirpuri-book;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #8d8d8d;
}

.form-dob {
  // max-width: 417.96px !important;
  width: 100% !important;
}
.termsandcondition {
  display: flex !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
}
.sihnupcounty {
  padding-right: 8px;
  padding-left: 0;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
}
.sihnupstate {
  padding-left: 8px;
  padding-right: 0;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
}
.signupcheckbox {
  margin-top: 0;
}
