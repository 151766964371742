.footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #14376f;
  color: white;
  text-align: center;
  padding-top: 48px;
  padding-bottom: 48px;
  @media screen and (max-width: 300px) {
    max-height: 100% !important;
  }
  .footer-inner-width {
      max-width: 600px;
      width: 90%;
      margin-top: 0;
  }
  height: 100%;
  .socialmedia {
    margin-bottom: 24px;
    a {
      // padding: 0 10px !important;
    }
    img {
      width: 24px;
      @media screen and (max-width: 300px) {
        width: 24px;
      }
    }
  }
  .nav {
    text-align: center;
    justify-content: center;
    gap: 8px;
    @media screen and (max-width: 370px) {
      padding-bottom: 0px;
    }
    a {
      font-family: $roboto-Condensed;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      padding: 0;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
    &.footer-nav-links{
      gap: 32px;
      &:before, &:after{
        display: none;
      }
      @media screen and (max-width:767px){
        row-gap:10px;        
      }
    }
    &.privacy-link{
      margin-top: 24px;
      gap: 32px;
      @media screen and (max-width:767px){
        &:before, &:after{
          display: none;
        }
      }
    }
  }
  .copyright {
    @media screen and (max-width: 370px) {
      padding-bottom: 15px;
    }
    p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      text-align: center !important;
      // letter-spacing: 1px;
      margin-bottom: 0;
      @media screen and (max-width: 300px) {
        margin-bottom: 1.5rem;
      }
      @media screen and (max-width: 360px) {
        letter-spacing: 0 !important;
        margin-bottom: 0 !important;
      }
      @media screen and (max-width: 300px) {
        margin-bottom: 10px !important;
      }
      text-align: right;
      color: #fff;
    }
  }
  .footerimg {
      img {
        width: 125px;
        margin-bottom: 32px;
      }
  }
  .hr-line{
    height: 2px;
    background-color: #fff;
    margin-top: 16px;
    margin-bottom: 24px;
  }
}
@media screen and (min-width: 800px) {
  .footerlogomobile {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .footerlogopc {
    display: none;
  }
}


.src-logo-box{
  padding: 0;
  margin: 0;
  text-align: center;
  span{    
      font-family: "MaisonNeue";
      font-size: 36px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: .9px;
      text-align: center;
      color: #000;    
  }
  span{
    &:nth-child(2){
      img{
        margin-left: 10px;
        position: relative;
        top: -7px;
      }
    }
  }
}