// $industry-bold: "industry-bold";
// $industry-Medium: "industry-Medium";
// $mirpuri-bold: "MaisonNeue-Bold";
// $mirpuri-book: "MaisonNeue";
$industry-bold: 'Roboto Condensed', sans-serif;
$industry-Medium: 'Roboto Condensed', sans-serif;
$mirpuri-bold: 'Roboto Condensed', sans-serif;
$mirpuri-book: 'Roboto Condensed', sans-serif;
$roboto-Condensed:'Roboto Condensed', sans-serif;

$primary-color-blue: #14376f;
$text-grey: #333333;
$red:#df0c00;
$brakt-red:#bf4343;
$green:#4d964c;
$blue:#1f5cbf;
$leader-tab:#454545;
$table-border:#f0f0f0; 
$table-headbg:#f3f3f3;
$black:#000000;
$tab-color:#6bd96d;
$slot-pink:#f0d8d8;
$slot-green:#dafad9;
