$Montserrat-Bold: "Montserrat-Bold";
$Montserrat-Medium: "Montserrat-Medium";
$Montserrat-Regular: "Montserrat-Regular";

.Maincontent {
  padding-top: 80px;

  // @media screen and (max-width: 700px) {
  //   padding-top: 0 !important;
  // }
  // @media screen and (max-width: 755px) {
  //   padding-top: 13% !important;
  // }
  @media screen and (max-width: 1000px) {
    padding-top: 30px;
  }

  @media screen and (max-width: 500px) {
    position: relative;
    top: 18px;
    padding-top: 0 !important;
  }
}

.star {
  color: red;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.container-fluid,
.container {
  padding-right: 0px;
  padding-left: 0px;
}

.container {
  max-width: 1180px;
}

.col-md-12,
.col-12 {
  padding-right: 0px;
  padding-left: 0px;
}

.leaderord {
  text-align: center;
  border-bottom: 1px solid #80808017;

  @media screen and (max-width: 500px) {
    margin-top: 6px !important;
  }

  @media screen and (max-width: 300px) {
    padding: 13px 20px !important;
    margin-top: 6px !important;
  }

  .lead-img {
    width: 100%;
    max-height: 100px;
    height: 100%;
    background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/new/placeholder2.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: inherit;
  }

  height: 104px;
  padding: 14px 92px 13px 92px;
  padding-right: 10vw;
  padding-left: 10vw;
  background-color: #f6f6f6;

  @media screen and (max-width: 767px) {
    padding: 13px 40px;
    margin-top: 0;
  }

  p {
    font-family: "MaisonNeue";
    font-size: 12px;

    @media screen and (max-width: 330px) {
      font-size: 10px;
    }

    @media screen and (max-width: 300px) {
      font-size: 8px !important;
    }

    @media screen and (max-width: 700px) {
      font-size: 10px;
      top: 0%;
      padding: 21px 28px;
      margin-bottom: 13px;
    }

    font-weight: normal;
    font-stretch: normal;
    vertical-align: middle;
    font-style: normal;
    height: 100%;
    justify-content: center;
    display: flex;
    line-height: 2;
    top: 40%;
    position: relative;
    letter-spacing: 4.46px;
    text-align: center;
    color: #a3a3a3;
  }
}

.hero-box {
  margin: 0 -15px;
}

.bg-img {
  img {
    width: 100%;
  }
}

.bgimage {
  // width: 100%;
  // height: 500px;
  // background-image: url("#{$imagePath}home-banner-bg.jpg");
  // background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/bg-images/header.jpg");
  @media screen and (max-width: 600px) {
    // background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/bg-images/mobile-banner1.jpg");
    // background-image: url("#{$imagePath}mobile-img.jpg");
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
  }

  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover;
  // background-attachment: inherit;
  position: relative;

  @media screen and (max-width: 990px) {
    // height: 600px;
  }

  .banner-overlay {
    // position: absolute;
    // top: 0;
    // left: 0;
    // background-color: #ebebeb;
    // opacity: 0.75;
    // width: 100%;
    // height: 100%;
  }
  .dt-banner {
    @media screen and (max-width: 1023px) {
      img {
        display: none;
      }
    }
  }

  .mb-banner {
    img {
      display: none;
    }

    @media screen and (max-width: 1023px) {
      img {
        display: block;
      }
    }
  }
  .row {
    position: absolute;
    width: 100%;
    bottom: 7%;

    @media screen and (max-width: 600px) {
      // position: absolute;
      bottom: 2%;
      width: 100%;
    }
    @media screen and (max-width: 425px) {
      // position: absolute;
      bottom: 0;
    }
  }

  @media screen and (max-width: 600px) {
    // height: 600px;
  }

  .container-fluid {
    position: relative;
    @media screen and (max-width: 600px) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .table {
    width: 50%;
    margin-bottom: 1rem;
    color: #212529;

    td {
      border-top: none;
      padding: 0.2rem;
    }

    .first {
      padding: 0.75rem;
      vertical-align: top;
      border-top: none;
      color: white;
      font-family: $industry-bold;
      font-size: 36px;

      @media screen and (max-width: 600px) {
        font-size: 34px;
      }

      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.38px;
      text-align: center;
      color: var(--white);

      .points {
        padding-left: 28px;
        padding-right: 28px;

        @media screen and (max-width: 600px) {
          padding-left: 9px;
          padding-right: 9px;
        }
      }
    }

    .second {
      padding: 0.75rem;
      vertical-align: top;
      border-top: none;
      color: white;

      font-family: $industry-Medium;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.46px;
      text-align: center;
      color: #cbcbcb;
    }
  }

  .sbs-logo-banner {
    text-align: center;
    margin-bottom: 16px;

    img {
      max-width: 187px;
      margin: 0 auto;
    }
  }

  .content {
    max-width: 775px;
    padding-top: 0%;
    padding-left: 0;
    margin: 0 auto;
    position: relative;
    top: 18%;

    @media screen and (max-width: 600px) {
      width: 100%;
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
      margin-bottom: 23px;
      // top: -30%;
    }

    .logo {
      text-align: left;

      img {
        @media screen and (max-width: 600px) {
          width: 31%;
        }
      }
    }
  }

  h1 {
    color: white;
    text-align: left;
    margin-top: 0%;
    font-family: $industry-bold;
    font-size: 56px;
    font-weight: 700;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.25;
    letter-spacing: normal;
    color: #14376f;
    text-align: center;
    margin-bottom: 12px;

    @media screen and (max-width: 990px) {
      font-size: 50px;
    }

    @media screen and (max-width: 600px) {
      font-size: 36px;
    }
  }

  h3 {
    font-family: $roboto-Condensed;
    font-size: 22px;
    font-style: italic;
    font-weight: 700;
    text-transform: uppercase;
    color: #14376f;
    line-height: 1.3;
    text-align: center;
    margin-bottom: 15px;

    @media screen and (max-width: 600px) {
      font-size: 18px;
    }
  }

  h4 {
    @media screen and (max-width: 600px) {
      width: 100%;
    }

    @media screen and (max-width: 1200px) {
      width: 100%;
      margin-top: 15px;
    }

    text-align: center;
    margin-top: 25px;
    width: 67.5%;
    font-family: $mirpuri-book;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 4px;
    color: var(--white);
    position: relative;
  }

  h4:before {
    content: "";
    display: block;
    width: 20%;

    @media screen and (max-width: 800px) {
      width: 20% !important;
    }

    @media screen and (max-width: 620px) {
      width: 20% !important;
    }

    @media screen and (max-width: 300px) {
      width: 10% !important;
    }

    height: 1px;
    background: #ededed;
    left: 0;
    top: 50%;
    position: absolute;
  }

  h4:after {
    content: "";
    display: block;
    width: 20%;

    @media screen and (max-width: 800px) {
      width: 20% !important;
    }

    @media screen and (max-width: 600px) {
      width: 20% !important;
    }

    @media screen and (max-width: 300px) {
      width: 10% !important;
    }

    height: 1px;
    background: #ededed;
    right: 0;
    top: 50%;
    position: absolute;
  }

  h6 {
    font-size: 1rem;
    text-align: left;
    color: white;
  }

  .logo {
    position: absolute;
    right: 24px;
    bottom: 24px;

    @media screen and (max-width: 600px) {
      right: 0;
      left: 0;
      margin: 0 auto;
      text-align: center;
    }

    img {
      width: 120px;
    }
  }

  .timer-outer {
    margin-left: 30px;

    @media screen and (max-width: 600px) {
      margin-left: 10px;
    }
  }

  .timer-style {
    display: flex;
    margin-top: 10px;
    width: 50%;
    margin: 10px auto 0px;
    justify-content: space-between;

    .timer-clock {
      color: #14376f;
      font-size: 32px;
      @media screen and (max-width: 600px) {
        font-size: 28px;
      }

      .separator {
        font-size: 28px;
        @media screen and (max-width: 600px) {
          font-size: 24px;
        }
      }

      .timer-name {
        color: #14376f;
      }
    }

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
}

.swapcol {
  @media screen and (max-width: 600px) {
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

.pado {
  @media screen and (max-width: 600px) {
    padding: 0 1px;
  }
}

.points_earned {
  width: 85%;
  height: 290px;
  padding: 41px 50px;
  margin-left: 22%;
  background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/Maymadness7stickets_website_V3.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: inherit;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 290px;
    padding: 25px 31px !important;
    margin-left: 0;
    margin-right: 0;
  }

  @media screen and (max-width: 800px) {
    height: 87.5%;
  }

  @media screen and (max-width: 1100px) {
    margin-left: 0%;
    padding: 15px 15px 30px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 290px;
    margin-left: 0;
    margin-right: 0;
  }

  .p_content {
    font-family: $mirpuri-book;
    text-align: left;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 2px;
    color: var(--white);
  }

  .mid-cont {
    font-family: $mirpuri-bold;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: 0.75px;
    color: var(--white);
    height: 200px;

    @include breakpoints(large) {
      font-size: 28px;
    }

    @include breakpoints(medium) {
      height: 160px;
    }
  }
}

.cardover {
  position: relative;
  z-index: 99;
  margin: 53px auto 0;
  background-color: #ede4e5;
  max-width: 1120px;

  @include breakpoints(medium) {
    top: 0;
    margin: 30px 0;
  }

  @include breakpoints(small) {
    margin: 0;
  }

  @media screen and (max-width: 767px) {
    margin-left: -0;
    margin-right: -0;
    position: initial;
    z-index: 9999;
    top: 0;
  }

  .card1 {
    padding-right: 0;
    padding-left: 0;

    img {
      width: 100%;
    }
  }

  .card2 {
    padding-left: 0;

    @media screen and (max-width: 767px) {
      padding: 31px 31px 40px;
    }
  }

  .sm-img {
    width: 100%;
    height: 340px;

    @media screen and (width: 768px) {
      max-height: 100%;
      height: 100%;
    }

    // background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/new/register-img.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: inherit;

    @media screen and (max-width: 767px) {
      height: 340px;
    }

    @media screen and (max-width: 340px) {
      margin-right: 2%;
    }
  }

  .log-reg {
    max-width: 100%;
    height: 100%;
    text-align: center;
    // background: white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: inherit;
    padding: 0px 75px;

    @media screen and (max-width: 340px) {
      height: auto;
    }

    @media screen and (max-width: 1200px) {
      padding: 20px;
      max-width: 310px;
      margin: 0 auto;
    }

    @media screen and (max-width: 767px) {
      padding: 0px;
    }

    @media screen and (max-width: 340px) {
      padding: 5%;
    }

    @include breakpoints(small) {
      text-align: center;
    }

    .register-txt {
      font-size: 12px;
      letter-spacing: 0.5px;
      line-height: 1.6;
      margin-bottom: 30px;
      color: #7f7b7c;
    }

    h4 {
      background-image: linear-gradient(to right, #14376f, #4d964c);
      font-family: $industry-Medium;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 1.53px;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;

      @include breakpoints(large) {
        font-size: 16px;
      }
    }

    h3 {
      background-image: linear-gradient(to right, #14376f, #4d964c);
      font-size: 25px;
      font-weight: bold;
      font-family: $industry-bold;
      letter-spacing: 0.78px;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 0;

      @include breakpoints(large) {
        font-size: 19px;
      }
    }

    .context {
      max-width: 310px;
      width: 100%;
      height: 20px;

      p {
        font-family: $mirpuri-book;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        text-align: initial;

        @media screen and (max-width: 600px) {
          text-align: center;
        }

        line-height: 1.71;
        letter-spacing: normal;
        color: #5e5e5e;
      }
    }

    .registerbtn {
      margin-bottom: 16px;
    }

    .regis-btn {
      text-transform: uppercase;
      border-right: 1px solid #4d964c;
      border-left: 1px solid #14376f;
      display: inline-table;
      max-width: 265px;

      @media screen and (max-width: 600px) {
        max-width: 300px;
      }

      width: 100%;
      color: white;
      background-image: linear-gradient(to right, #14376f, #4d964c);
      font-family: $mirpuri-book;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.7;
      letter-spacing: 1px;
      text-align: center;
      color: var(--white);
      border-radius: 0;
    }

    .login-btn {
      text-transform: uppercase;
      max-width: 265px;
      display: inline-table;

      @media screen and (max-width: 600px) {
        max-width: 300px;
        border-width: 1px;
      }

      width: 100%;
      height: 46px;
      border-style: solid;
      border-width: 2px;
      border-color: white;
      border-image-source: linear-gradient(to right, #14376f, #4d964c);
      border-image-slice: 1;
      background-color: var(--white);
      font-family: $mirpuri-book;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center;
      color: #14376f;
    }
  }
}

.steps {
  width: 100%;
  height: 348px;
  margin: 0;
  padding: 50px 8vw 48px;
  background-color: #f6f7f8;

  @include breakpoints(large) {
    height: auto;
  }

  @media screen and (max-width: 600px) {
    padding: 30px 0px !important;
    height: auto !important;
    padding-bottom: 30px;
    background-color: white;
  }

  @media screen and (max-width: 800px) {
    height: auto;
  }

  @media screen and (max-width: 1100px) {
    padding: 30px 5px !important;
  }

  @media screen and (max-width: 1200px) {
    padding: 50px 0px 25px !important;
  }

  @media screen and (max-width: 1340px) {
    padding: 50px 50px 48px;
  }

  background-color: #f6f7f8;

  .pad3o {
    padding: 0 30px;

    @include breakpoints(large) {
      padding: 0 10px;
    }
  }

  .step-box {
    width: 100%;
    margin: 0 39px 2px 0;
    padding: 29px 10px 29px 0px;

    @media screen and (width: 768px) {
      padding: 10px;
    }

    @media screen and (max-width: 1300px) {
      padding: 29px 5px 22px 5px;
    }

    border: solid 1px #e6e6e6;
    background-color: var(--white);

    @media screen and (max-width: 600px) {
      background-color: transparent;
      border: none;
      margin: 0 39px 20px 0;
    }

    @media screen and (max-width: 600px) {
      padding: 0;
    }

    border: solid 1px #e6e6e6;
    background-color: var(--white);

    .mid-line {
      display: inline-block;
      width: 1px;
      position: absolute;
      height: 120px;
      top: 20%;
      opacity: 0.25;
      background-color: #979797;

      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    .border {
      border-left: 1px solid #979797 !important;
      border-right: none !important;
      border-bottom: none !important;
      border-top: none !important;
      padding-left: 12px;

      @media screen and (max-width: 600px) {
        border: none !important;
      }
    }

    .steps-con {
      @media screen and (max-width: 600px) {
        width: 100% !important;
        padding: 27px !important;
        margin: 10px 0;
        box-shadow: 0 2px 22px 0 rgba(230, 230, 230, 0.21);
        border: solid 1px #e6e6e6;
        background-color: var(--white);
      }

      @media screen and (max-width: 1300px) {
        padding: 5px 18px;
      }

      h1 {
        opacity: 0.43;
        background-image: linear-gradient(to bottom, #4d964c, #14376f);
        font-family: $mirpuri-book;
        font-size: 22px;

        @include breakpoints(large) {
          font-size: 18px;
        }

        @media screen and (max-width: 600px) {
          font-size: 27px;
        }

        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.55px;
        text-align: left;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      p {
        font-family: $mirpuri-book;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        color: #3f3f3f;
        text-align: left;
      }

      width: 33%;
      padding: 0 28px;
      display: inline-block;
      height: 100%;

      @media screen and (max-width: 600px) {
        width: 100px;
      }

      @media screen and (max-width: 800px) {
        padding: 0 15px;
      }

      display: inline-block;
      height: 100%;

      .step-icon {
        text-align: left;
        padding-bottom: 18px;
        padding-top: 28px;

        img {
          @media screen and (max-width: 300px) {
            width: 40px;
          }
        }

        .ste {
          display: inline-block;
        }

        .icon {
          padding-right: 20px;

          @include breakpoints(large) {
            padding-right: 10px;
          }

          @media screen and (max-width: 800px) {
            padding-right: 5px;
          }
        }

        .text {
          width: 100%;
          max-width: 135px;

          @include breakpoints(large) {
            font-size: 16px;
          }

          @media screen and (max-width: 300px) {
            display: inline-flex !important;
            font-size: 14px !important;
          }

          @media screen and (width: 768px) {
            height: 70px;
          }

          @media screen and (max-width: 600px) {
            max-width: 220px;
            display: inline;
          }

          @media screen and (max-width: 800px) {
            max-width: 100px;
            font-size: 16px;
            padding-top: 15px;
          }

          @media screen and (max-width: 700px) {
            max-width: 95px;
            font-size: 16px;
          }

          padding-top: 5px;
          vertical-align: middle;
          background-image: linear-gradient(265deg, #4d964c, #14376f);
          font-family: $industry-bold;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: 2px;
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  .place-b {
    display: none !important;
    height: 250px;
    text-align: center;
    justify-content: center;
    width: 100%;
    display: flex;

    @include breakpoints(medium) {
      margin-top: 20px;
      text-align: center;
      justify-content: center;
      width: 100%;
      display: flex;
    }

    @include breakpoints(small) {
      margin-top: 10px;
      height: auto;
    }

    @media screen and (max-width: 600px) {
      padding: 30px 38px;
      background-color: #f6f6f6;
    }

    .place-lo {
      width: 100%;
      margin: 0 !important;
      max-width: 300px;
      max-height: 250px;
      height: 100%;
      background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/new/300x250-placeholder.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-attachment: inherit;

      @media screen and (max-width: 700px) {
        width: 100%;
        height: 250px;
      }

      p {
        font-family: $mirpuri-book;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 4.46px;
        text-align: center;
        color: #a3a3a3;
      }
    }
  }
}

.wht-bg-box {
  width: 100%;
  padding: 50px 0 0 0;

  @include breakpoints(large) {
    padding: 30px 0 0 0;
  }

  @media screen and (max-width: 600px) {
    padding: 10px 0 0 0;
  }

  background-color: var(--white);

  p {
    margin-bottom: 49px;
    font-family: $mirpuri-book;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: 0.9px;
    text-align: center;
    color: #000000;

    @include breakpoints(large) {
      font-size: 30px;
      margin-bottom: 30px;
    }

    @media screen and (max-width: 300px) {
      font-size: 20px !important;
    }

    @media screen and (max-width: 600px) {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }

  .bg-img {
    width: 100%;
    height: 524px;
    // background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/new/fan.jpg");
    background: url("../assets/images/crc-latest.jpg");

    @media screen and (max-width: 600px) {
      // background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/bg-images/Bitmap2.jpg");
      background: url("../assets/images/crc-latest.jpg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-attachment: inherit;
      height: 310px;
    }

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: inherit;

    @media screen and (max-width: 600px) {
      border: none !important;
    }

    .bg-content {
      width: 465px;
      height: 224px;
      right: 0;
      bottom: 0;
      position: absolute;
      padding: 46px 23px 46px 49px;
      background-color: var(--white);

      @media screen and (max-width: 600px) {
        height: 124px;
        width: 100%;
        position: absolute;
        padding: 15px 35px 26px 31px;
        background-color: var(--white);
      }

      p {
        font-family: $mirpuri-book;
        font-size: 24px;

        @media screen and (max-width: 600px) {
          font-size: 18px;
          margin: 0;
          padding-bottom: 26px;
        }

        @media screen and (max-width: 300px) {
          font-size: 15px !important;
        }

        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        text-align: left;
        line-height: 1.83;
        letter-spacing: 0.6px;
        color: #14376f;
      }
    }
  }
}

.cards-box {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 32px 23px 0 23px;
  border: none;
  background-color: var(--white);

  @media screen and (max-width: 800px) {
    margin: 25px 0 0;
    padding: 5px;
    width: 100%;
    height: 100%;
  }

  // @media screen and (max-width: 1300px) {
  //   margin: 15px 99px;
  //   padding: 5px;
  //   width: 100%;
  //   height: 100%;
  // }
  // @media screen and (max-width: 2500px) {
  //   margin: 15px 99px;
  //   padding: 5px;
  //   width: 100%;
  //   height: 100%;
  // }
  @media screen and (max-width: 600px) {
    height: 100%;
    margin: 0px;
    padding: 0;
    border: none;
    background-color: var(--white);
  }

  .mid-line {
    top: 35%;
    width: 1px;
    display: inline-block;
    height: 80px;
    position: absolute;
    background-color: #dcdcdc;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  .points-cards {
    max-width: 289px;

    @media screen and (min-width: 1671px) {
      max-width: 300px !important;
    }

    width: 100%;
    margin: 0 33px;
    display: inline-block;
    height: 100%;

    .cards-box .points-cards {
      max-width: 390px;
      width: 100%;
      margin: 0 33px;
      display: inline-block;
      height: 100%;
    }

    @media screen and (min-width: 1800px) {
      max-width: 21% !important;
    }

    @media screen and (max-width: 2000px) {
      display: inline-table;
    }

    @media screen and (max-width: 1500px) {
      width: 24%;
      margin: 0 1%;
      height: auto;
    }

    @media screen and (max-width: 1400px) {
      width: 22%;
      margin: 0 1%;
    }

    @include breakpoints(large) {
      width: 22.5%;
    }

    @media screen and (max-width: 600px) {
      max-width: 350px;
      width: 99%;
      margin: 0;
      margin-left: 0%;
      display: block;
      border-top: 1px solid #dcdcdc;
      height: auto;
      padding: 20px 0 20px 0;
      text-align: left;
      padding-right: 0px;
      display: inline-flex;
      align-items: center;
    }

    .text {
      @media screen and (max-width: 600px) {
        display: inline-block;
        width: 75%;
      }
    }

    .step-icon {
      padding-bottom: 22px;
      text-align: left;

      img {
        @media screen and (max-width: 300px) {
          width: 40px;
        }
      }

      @media screen and (max-width: 600px) {
        text-align: left;
        padding-right: 10px;
        display: inline-block;
        vertical-align: text-bottom;
        width: 20%;
        height: 100%;
        padding-bottom: 0;
      }
    }

    p {
      @include breakpoints(large) {
        font-size: 16px;
      }

      @include breakpoints(medium) {
        font-size: 13px;
      }

      @media screen and (max-width: 600px) {
        display: inline-block;
        font-size: 19px;
        vertical-align: middle;
        margin-bottom: 0;
      }

      font-family: $mirpuri-book;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      text-align: left;
      line-height: 1.5;
      letter-spacing: 0.5px;
      color: #14376f;
    }
  }
}

.videosection {
  position: relative;

  img {
    max-width: 800px;
    width: 100%;
    max-height: 376px;
    height: 100%;

    @media screen and (max-width: 600px) {
      width: 100%;
      height: 276px;
    }
  }

  a {
    position: absolute;
    display: block;
    background: url(https://d7le4ielk3ho1.cloudfront.net/src_images/play-button.png);
    height: 100%;
    width: 78px;
    top: 40%;
    left: 45%;
    background-size: 100%;
    background-repeat: no-repeat;

    @media screen and (max-width: 1100px) {
      left: 40% !important;
      top: 35%;
    }

    @media screen and (max-width: 600px) {
      width: 20%;
      top: 100px;
    }
  }
}

.video-text {
  height: auto;
  padding: 20px 0;

  @include breakpoints(large) {
    padding: 10px 0;
  }

  @media screen and (max-width: 600px) {
    padding: 20px 15px;
  }

  p {
    font-family: $roboto-Condensed;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    text-align: center;
    color: #454545;

    @include breakpoints(large) {
      font-size: 18px;
      padding: 0 50px;
    }

    @include breakpoints(small) {
      padding: 0 0;
    }

    @media screen and (max-width: 600px) {
      font-size: 18px;
    }
  }
}

.vid-l {
  padding-left: 131px;

  @include breakpoints(large) {
    padding-left: 20px;
  }

  @media screen and (max-width: 767px) {
    padding-right: 0;
    padding-left: 0;
  }

  @media screen and (width: 768px) {
    padding-left: 15px;
  }
}

.vid-r {
  .videosection {
    text-align: right;
  }

  padding-right: 131px;

  @include breakpoints(large) {
    padding-right: 20px;
  }

  @media screen and (max-width: 767px) {
    padding-right: 0;
    padding-left: 0;
  }

  @media screen and (width: 768px) {
    padding-right: 15px;
  }

  @include breakpoints(small) {
    padding-right: 0px;

    .video-text {
      padding-bottom: 0;
    }
  }
}

.faq-box {
  max-height: 1000px;
  height: 100%;

  // padding: 53px 379px 50px;
  @include breakpoints(large) {
    margin-top: 20px;
  }

  @media screen and (max-width: 600px) {
    padding: 25px !important;
  }

  @media screen and (max-width: 1400px) {
    padding: 15px;
  }

  @media screen and (max-width: 900px) {
    padding: 5%;
  }

  // background-color: #f6f6f6;
  padding: 25px 379px 25px;
  background-color: white;

  .faq-inerbox {
    width: 100%;

    @media screen and (max-width: 1300px) {
      max-width: 100%;
    }

    p {
      font-family: $mirpuri-bold;
      font-size: 30px;

      @media screen and (max-width: 767px) {
        font-size: 24px;
        margin-bottom: 22px;
      }

      @media screen and (max-width: 300px) {
        font-size: 20px !important;
      }

      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.47;
      letter-spacing: 0.75px;
      margin-bottom: 33px;
      text-align: center;
      color: #000000;
    }
  }
}

.btn-faq {
  max-width: 244px;
  width: 100%;
  height: 45px !important;
  background-color: #14376f;
  border-radius: 0;
  font-family: $mirpuri-book;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: var(--white);
  margin-top: 11px;
}

.accordions {
  @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

  /* Style the accordion section */
  .accordion__section {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  /* Style the buttons that are used to open and close the accordion panel */
  .accordion {
    border-top: solid 1px #dadada !important;
    border-right: solid 1px #dadada !important;
    border-left: solid 1px #dadada !important;
    background-color: white;
    height: 46px;
    color: #444;
    cursor: pointer;
    padding: 18px 25px 15px 20px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
  }

  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .accordion:hover,
  .active {
    background-color: white;
  }

  /* Style the accordion content title */
  .accordion__title {
    font-family: $mirpuri-book;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #2f2f2f;
    text-align: left;
  }

  /* Style the accordion chevron icon */
  .accordion__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
  }

  /* Style to rotate icon when state is active */
  .rotate {
    transform: rotate(90deg);
  }

  /* Style the accordion content panel. Note: hidden by default */
  .accordion__content {
    background-color: white;
    overflow: hidden;
    transition: max-height 0.6s ease;
    border-right: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    border-left: 1px solid #dadada;
  }

  /* Style the accordion content text */
  .accordion__text {
    font-family: $mirpuri-book;
    font-size: 13px;
    margin-top: 4px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: 0.46px;
    color: #616161;
    padding: 15px 62px 17px 21px;
    text-align: left;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.placeleaderbox {
  padding: 2%;

  @media screen and (max-width: 1300px) {
    padding: 2%;
  }

  @media screen and (max-width: 800px) {
    padding: 4% 10%;
  }

  @media screen and (max-width: 500px) {
    padding: 4% 10%;
    margin-bottom: 10px;
  }

  background-color: white;

  .box {
    display: inline-block;
    max-width: 300px;
    width: 100%;
    height: 100px;
    margin: 15px 25px 15px 25px;

    @media screen and (max-width: 1300px) {
      margin: 1%;
    }

    @media screen and (max-width: 800px) {
      margin: 15px 15px 15px 0;
    }

    padding: 26px 28px;

    @media screen and (max-width: 300px) {
      padding: 16px 28px !important;
    }

    background-color: #e6e6e6;

    @media screen and (max-width: 600px) {
      margin: 10px 0px 10px 0;
    }

    p {
      font-family: $mirpuri-book;
      font-size: 12px;

      @media screen and (max-width: 300px) {
        font-size: 12px !important;
      }

      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: 4.46px;
      text-align: center;
      color: #a3a3a3;
    }
  }
}

.mid-cont {
  .carousel-indicators {
    display: none !important;
  }

  .carousel-numb {
    display: block !important;
  }

  .carousel {
    height: 100%;
  }

  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    height: 80%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }

  .carousel-item {
    position: initial !important;
  }

  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: auto;
    height: 70%;
  }

  .carousel-caption {
    position: absolute;
    right: 1% !important;
    bottom: 20px;
    left: 1% !important;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: left;
  }

  .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    height: 80%;
    display: flex;
    align-items: flex-end;
    justify-content: left;
    width: 65%;
    color: #fff;
    text-align: left;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }
}

.adminleaderbord {
  width: 100%;

  @media screen and (max-width: 384px) {
    width: 100%;
  }

  background-color: #f6f6f6 !important;
  border: none;

  .adminled-box {
    max-width: 162px;
    padding: 5px 0;
    width: 100%;

    @media screen and (max-width: 767px) {
      margin-right: 0px;
    }

    height: 76px;
    display: inline-block;
    border: solid 1px #dee0e3;
    margin-right: 5px;
    background-color: var(--white);

    .table {
      width: 100%;
      height: 100%;
      margin-bottom: 0;
      color: #212529;

      .team {
        text-align: left;

        img {
          width: 16%;
          height: 15px;
          margin: 0 0 10px;
          margin-right: 6px;
        }

        span {
          white-space: nowrap;
          overflow: hidden;
          max-width: 9em;
          font-family: $mirpuri-book;
          font-size: 12px;
          font-weight: bold;
          margin: 0 0 10px;
          display: inline-block;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.87px;
          color: #272727;
          vertical-align: bottom;
        }
      }

      .looserteam {
        .team {
          span {
            color: #8d8d8d !important;
          }
        }

        .score {
          color: #8d8d8d !important;
        }
      }

      .score {
        text-align: right;
        font-family: $mirpuri-book;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        padding: 0 5px !important;
        line-height: normal;
        letter-spacing: 0.87px;
        text-align: right;
        color: #272727;
      }

      .final {
        text-align: right;
        font-family: $mirpuri-book;
        font-size: 11px;
        padding: 0px 5px 10px !important;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: right;
        color: #878787;
        text-transform: uppercase;
      }

      .gender {
        text-align: right;
        font-family: $mirpuri-book;
        font-size: 11px;
        font-weight: normal;
        padding: 0px 5px 10px !important;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: left;
        color: #878787;
        text-transform: uppercase;
      }

      td {
        padding: 0px 5px !important;
        line-height: 1.428571429;
        vertical-align: top;
        border-top: none i !important;
      }
    }
  }
}

.slider {
  .lead-img {
    height: 60px;

    p {
      top: 30%;
    }
  }

  .adminleaderbord .adminled-box .table .team {
    text-align: left;
    display: table-cell;
    padding-right: 0 !important;
  }

  .slick-prev:before {
    content: url(https://d7le4ielk3ho1.cloudfront.net/src_images/new/score-arrow-left.svg) !important;
  }

  .slick-next:before {
    content: url(https://d7le4ielk3ho1.cloudfront.net/src_images/new/score-arrow-right.svg) !important;
  }

  .slick-next {
    right: -38px;
    top: 38px;

    @media screen and (max-width: 1200px) {
      right: -25px !important;
    }
  }

  .slick-prev {
    left: -40px;
    top: 38px;

    @media screen and (max-width: 1200px) {
      left: -20px !important;
    }
  }

  width: 100%;
  height: 100%;
  background: #f6f6f6 !important;
  border-bottom: none !important;

  .carousel {
    width: 100%;
    background-color: #f6f6f6;
    height: 100%;
    text-align: center;

    @media screen and (max-width: 380px) {
      text-align: initial !important;
    }

    .carousel-control-prev-icon {
      background-image: url(https://d7le4ielk3ho1.cloudfront.net/src_images/new/score-arrow-left.svg) !important;
    }

    .carousel-control-next-icon {
      background-image: url(https://d7le4ielk3ho1.cloudfront.net/src_images/new/score-arrow-right.svg) !important;
    }

    .carousel-inner {
      height: 100%;

      .active {
        height: 100% !important;
      }
    }

    .carousel-indicators {
      display: none !important;
    }

    .carousel-control-prev {
      left: -5%;
      width: 5% !important;
    }

    .carousel-control-next {
      right: -5%;
      width: 5% !important;
    }
  }
}

.allscore {
  position: absolute;
  right: 0;
  top: 15px;

  .allscrebtn {
    max-width: 100px;
    width: 100%;
    height: 70px;
    border: solid 1px #dee0e3;
    background-image: linear-gradient(to bottom, var(--white), #fefefe);
    font-family: $mirpuri-book;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    color: #062f6e;
  }
}

.checkyourl {
  .place-lo {
    max-width: 300px !important;
    width: 100%;
  }

  .placeleaderbox {
    padding: 0;

    .box {
      display: flex;
      vertical-align: middle;
      padding: 30px 0;
      margin: 0;

      p {
        font-family: "MaisonNeue";
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        padding: 0;
        font-style: normal;
        line-height: 2;
        /* letter-spacing: 4.46px; */
        text-align: center;
        color: #a3a3a3;
      }
    }
  }

  padding: 0 2.5vw;

  @media screen and (max-width: 800px) {
    padding-top: 30px;
    padding: 0 0 !important;
  }

  p {
    font-family: $mirpuri-bold;
    font-size: 36px;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      padding-bottom: 28px;
      padding-top: 30px;
    }

    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: 0.9px;
    text-align: center;
    color: #000000;
    padding: 40px;
    margin-bottom: 0;
  }

  .leadbordhead {
    :nth-child(1) {
      width: 20%;
      text-align: center;
    }

    :nth-child(2) {
      width: 40%;
      text-align: left;
    }

    :nth-child(3) {
      width: 40%;
      text-align: left;
      vertical-align: bottom;
    }

    :nth-child(4) {
      width: 20%;
      text-align: center;
      vertical-align: bottom;

      @media screen and (max-width: 400px) {
        vertical-align: baseline !important;
      }
    }
  }

  .detailbox {
    .table {
      width: 100%;
      margin-bottom: 0px !important;
    }

    max-width: 100%;
    width: 100%;
    height: 437px;
    padding: 28px 22px;

    @media screen and (max-width: 767px) {
      padding: 22px 5px;
      margin-bottom: 20px;
      height: 467px;
      max-width: 100%;
    }

    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.01);
    border: solid 1px #d2d2d2;
    background-color: var(--white);

    thead {
      tr {
        font-family: $mirpuri-book;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.14;
        letter-spacing: 0.32px;
        color: #333333;
        text-align: left;

        :nth-child(2) {
          text-align: right;
          padding-right: 15px;
        }

        :nth-child(1) {
          width: 12%;
          text-align: left;
          padding: 10px 0;
        }

        :nth-child(2) {
          text-align: left;
          padding: 10px 5px;
          width: 36%;

          @media screen and (max-width: 1250px) {
            width: 60%;
          }

          @media screen and (max-width: 1210px) {
            width: 55%;
          }

          @media screen and (max-width: 1030px) {
            width: 34%;
          }

          @media screen and (max-width: 800px) {
            width: 35%;
          }
        }
      }
    }

    p {
      width: 100%;
      height: 10%;

      .textL {
        font-family: $mirpuri-book;
        font-size: 25px;

        @media screen and (max-width: 1352px) {
          font-size: 20px;
        }

        @media screen and (max-width: 7700px) {
          font-size: 19px;
        }

        @media screen and (max-width: 600px) {
          font-size: 20px !important;
        }

        @media screen and (max-width: 300px) {
          font-size: 18px !important;
        }

        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        padding: 0;
        letter-spacing: 1.25px;
        color: #14376f;
        text-align: left;
        float: left;
      }

      .textR {
        text-align: right;

        button {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;

          @media screen and (min-width: 700px) {
            display: grid;
          }

          @media screen and (min-width: 700px) {
            padding-top: 5px;
          }

          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: right;
          color: #0f4d85;
          max-width: 81px;
          width: 100%;

          @media screen and (max-width: 600px) {
            height: 30px;
          }

          @media screen and (max-width: 1352px) {
            height: 30px;
          }

          height: 40px;
          float: right;
        }
      }
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #14376f;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #154085;
  }

  .detailbox2 {
    width: 100%;
    height: 320px;
    overflow: auto;

    .table {
      width: 100%;
    }

    tbody {
      tr {
        border-top: solid 1px #f0f0f0;
        border-bottom: solid 1px #f0f0f0;
        vertical-align: middle;
        justify-content: flex-start;
        height: 67px;

        :nth-child(1) {
          width: 12%;
          text-align: -webkit-match-parent;
          vertical-align: middle;
        }

        :nth-child(2) {
          // position: relative;
          width: 36%;
          padding: 10px 6px;
          vertical-align: middle;
          font-family: "MaisonNeue";
          font-size: 14px;
          font-weight: 500;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 1px;

          abbr[title] {
            text-decoration: none;
          }

          abbr[title]:hover::after,
          abbr[title]:focus::after {
            content: attr(title);
            position: absolute;
            left: 10%;
            // bottom: -30px;
            width: auto;
            white-space: nowrap;
            /* style tooltip */
            background-color: #1e1e1e;
            color: #fff;
            border-radius: 3px;
            box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
            font-size: 14px;
            padding: 3px 5px;
          }
        }

        :nth-child(3) {
          // position: relative;
          width: 36%;
          padding: 10px 12px;
          vertical-align: middle;
          font-size: 14px;
          font-weight: 500;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 1px;

          abbr[title] {
            text-decoration: none;
          }

          abbr[title]:hover::after,
          abbr[title]:focus::after {
            content: attr(title);
            position: absolute;
            left: 50%;
            // bottom: -30px;
            width: auto;
            white-space: nowrap;
            /* style tooltip */
            background-color: #1e1e1e;
            color: #fff;
            border-radius: 3px;
            box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
            font-size: 14px;
            padding: 3px 5px;
          }
        }

        :nth-child(4) {
          width: 16%;
          padding: 10px 6px;
          vertical-align: middle;
          text-align: end;
        }
      }

      .profile-img {
        width: 40px;
        height: 40px;
        float: left;
        margin-right: 25px;
        display: flex;

        img {
          width: auto;
          border-radius: 100%;
          height: 40px;
          width: 40px;
        }
      }

      .profile-nme {
        font-family: $mirpuri-book;
        font-size: 14px;
        font-weight: 500;
        padding-top: 0;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #0d0d0d;
        float: left;
        font-weight: bold;

        .username-style {
          font-weight: normal;
        }
      }

      .profile-rank {
        font-family: $mirpuri-book;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0;
        letter-spacing: normal;
        text-align: right;
        color: #14376f;
      }

      .profile-score {
        font-family: $mirpuri-book;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0;
        letter-spacing: normal;
        text-align: right;
        color: #14376f;
      }
    }
  }
}

.conatiner1 {
  .detailbox {
    float: right;
  }
}

.p_content {
  .container {
    height: 100%;

    @media screen and (max-width: 700px) {
      height: 150%;
    }

    padding: 0;

    .slick-slider {
      height: 75%;

      @media screen and (max-width: 1370px) {
        height: 85%;
      }

      @media screen and (max-width: 600px) {
        height: 90%;
      }

      @media screen and (max-width: 768px) {
        height: 70%;
      }

      @media screen and (max-width: 300px) {
        height: 85% !important;
      }

      .slick-prev {
        left: 0px !important;
      }

      .slick-next {
        right: 60% !important;

        @media screen and (max-width: 768px) {
          right: 56% !important;
        }

        @media screen and (max-width: 600px) {
          right: 52% !important;
        }

        @media screen and (max-width: 400px) {
          right: 57% !important;
        }
      }

      .slick-prev,
      .slick-next {
        height: 30px !important;
        // width: 44px !important;
      }

      .slick-prev:before,
      .slick-next:before {
        font-family: "slick";
        font-size: 45px !important;
        line-height: 1;
        opacity: 0.75;
        color: white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      .slick-prev:before {
        content: url(https://d7le4ielk3ho1.cloudfront.net/src_images/left-arrow.svg) !important;
      }

      .slick-next:before {
        content: url(https://d7le4ielk3ho1.cloudfront.net/src_images/right-arrow.svg) !important;
      }

      .slick-prev,
      .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 90% !important;
        z-index: 1;

        @media screen and (max-width: 800px) {
          top: 70% !important;
        }

        @media screen and (max-width: 767px) {
          top: 58% !important;
        }

        @media screen and (max-width: 600px) {
          top: 90% !important;
        }

        display: block;
        width: 20px;
        height: 20px;
        padding: 0;
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        background: transparent;
      }

      .slick-list {
        height: 100%;

        .slick-track {
          height: 100%;
        }

        .slick-slide {
          height: 100%;

          div {
            height: 100%;

            p {
              height: 100%;
            }
          }
        }
      }

      .slick-dots {
        bottom: -6px;
        width: 45%;

        @media screen and (max-width: 600px) {
          width: 49% !important;
          bottom: -2px !important;
          top: 90% !important;
        }

        @media screen and (max-width: 800px) {
          bottom: 30px;
          width: 45%;
          top: 70%;
        }

        @media screen and (max-width: 300px) {
          width: 53% !important;
        }

        @media screen and (max-width: 767px) {
          bottom: 30px;
          width: 45%;
          top: 60%;
        }

        ul {
          margin: 0px auto !important;
          text-align: -webkit-center;
          padding: 0 !important;
          max-width: 30px;
        }

        li {
          display: none !important;
        }

        .slick-active {
          display: block !important;

          div {
            font-family: $mirpuri-book;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: var(--white);
          }
        }
      }
    }
  }
}

.pointsect {
  @media screen and (max-width: 600px) {
    padding-right: 0;
    padding-left: 30px;
    background: white;
  }
}

.faq-button {
  p {
    font-family: MaisonNeue;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #2f2f2f;

    a {
      color: #14376f;
      font-weight: bold;
    }

    a:hover {
      color: #14376f !important;
    }
  }

  a {
    line-height: 2;
  }

  a:hover {
    color: white;
  }

  text-align: center;

  button {
    &:hover {
      color: #ffffff;
    }
  }
}

input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  /* create custom radiobutton appearance */
  display: inline-block !important;
  padding: 3px !important;
  /* background-color only for content */
  background-clip: content-box !important;
  border: 1px solid #bbbbbb !important;
  background-color: #e7e6e7 !important;
  border-radius: 50% !important;
}

.form-check label {
  margin-top: 3px;
  padding-left: 5px;
}

/* appearance for checked radiobutton */
input[type="radio"]:checked {
  background-color: #14376f !important;
  border: 1px solid #14376f !important;
  outline: none;
}

.form-check-input {
  position: absolute !important;
  top: 3px;
  margin-top: 0px !important;
  margin-left: -1.8rem !important;
}

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  width: 20px;
  height: 20px;
}

input[type="checkbox"]:checked + label:after {
  background-color: #14376f;
  color: white;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 1.25rem;
  /* margin-right: 0.75rem; */
}

.small-adds-outer {
  background-color: #ffffff;
  padding: 0px 0;

  @include breakpoints(medium) {
    padding: 0px 0 25px;
  }

  @include breakpoints(small) {
    padding: 20px 0 25px;
    margin-bottom: 10px;
  }

  .small-adds-inner {
    @include wrap-flx;
    justify-content: space-around;
    flex-wrap: wrap;

    @include breakpoints(medium) {
      justify-content: space-between;
      max-width: 95%;
      margin: 0 auto;
    }

    .box {
      max-width: 300px;
      width: 100%;
      height: 100px;
      padding: 26px 28px;
      background-color: #e6e6e6;
      text-align: center;
      line-height: 2;

      @include breakpoints(medium) {
        max-width: 32%;
        height: auto;
        padding: 26px 28px 15px;
      }

      @include breakpoints(small) {
        max-width: 90%;
        margin: 0 auto 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      p {
        font-size: 12px;
        font-family: $mirpuri-book;
        color: #a3a3a3;
        letter-spacing: 4.46px;
      }
    }
  }
}

.place-lo {
  width: 100%;
  height: 250px;
  background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/new/300x250-placeholder.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: inherit;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 250px;
    margin: 30px 0%;
    display: flex;
    text-align: center;
  }

  @media screen and (max-width: 600px) {
    margin: 30px 0% !important;
  }

  @media screen and (max-width: 500px) {
    margin: 30px 0 !important;
  }

  @media screen and (max-width: 380px) {
    margin: 30px !important;
  }

  p {
    font-family: $mirpuri-book;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 4.46px;
    text-align: center;
    color: #a3a3a3;
  }
}

.userhome {
  .checkyourl {
    @media screen and (max-width: 1331px) {
      padding: 0;
    }

    @media screen and (max-width: 767px) {
      padding-top: 0px !important;
    }

    .detailbox {
      @media screen and (max-width: 840px) {
        padding: 22px 5px;
      }

      @media screen and (max-width: 600px) {
        padding: 22px 10px !important;
      }
    }

    .col-md-4 {
      @media screen and (max-width: 600px) {
        padding: 0;
      }

      @media screen and (max-width: 1210px) {
        flex: 50%;
        max-width: 50%;
        padding: 0 2vw;
      }

      @media screen and (max-width: 815px) {
        padding: 0 15px !important;
      }

      @media screen and (max-width: 767px) {
        flex: 100% !important;
        max-width: 100% !important;
        padding: 0px;
      }
    }

    .col-md-3 {
      @media screen and (max-width: 1210px) {
        position: inherit;
        flex: 100% 1;
        padding: 0 !important;
        max-width: 100%;
        height: 400px;
      }
    }
  }

  .bgimage {
    width: 100%;
    // height: 475px;

    @media screen and (max-width: 990px) {
      // height: 600px;
    }
  }

  .hero-box {
    // .timer-style {
    //   display: none !important;
    // }
    h4 {
      display: none !important;
    }
  }
}

.champmob {
  height: 660px;

  @media screen and (max-width: 767px) {
    height: 600px;
    padding: 0;
    margin-bottom: 10%;
  }

  @media screen and (max-width: 690px) {
    height: 450px !important;
    padding: 0;
    margin-bottom: 30%;
  }

  @media screen and (max-width: 600px) {
    height: 450px !important;
    padding: 0;
    margin-bottom: 50%;
  }

  @media screen and (max-width: 400px) {
    height: 450px !important;
    padding: 0;
    margin-bottom: 60%;
  }

  background-color: white;
  padding: 40px 0;

  @include breakpoints(large) {
    padding: 40px 0;
  }

  @include breakpoints(small) {
    padding: 15px 0 40px;
  }

  .championship-box {
    position: relative;
  }

  .champswaprow {
    @media screen and (max-width: 767px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }

    .col-md-4 {
      height: 520px;

      @media screen and (max-width: 767px) {
        padding-top: 30px !important;
        padding-left: 24px !important;
        margin-top: 80px;
      }

      .champ-textbox {
        width: 100%;
        height: 100%;
        position: relative;

        .text-box {
          max-width: 441px;
          width: 100%;
          position: absolute;
          right: -31%;
          z-index: 99;
          top: 35%;

          @include breakpoints(large) {
            top: 28%;
            right: -6%;
          }

          @media screen and (max-width: 1240px) {
            top: 28%;
            right: -6%;
          }

          @media screen and (max-width: 767px) {
            position: initial;
          }

          .champ-bold-text {
            font-family: $industry-Medium;
            font-size: 40px;

            @include breakpoints(large) {
              font-size: 28px;
            }

            @media screen and (max-width: 750px) {
              font-size: 24px;
            }

            font-weight: bold;
            font-stretch: normal;
            font-style: italic;
            line-height: 1.38;
            letter-spacing: 2.42px;
            color: #000000;
            margin-bottom: 15px;
          }

          .champ-light-text {
            max-width: 315px;
            width: 100%;
            font-family: $mirpuri-book;
            font-size: 16px;

            @media screen and (max-width: 767px) {
              font-size: 14px;
            }

            @media screen and (max-width: 300px) {
              font-size: 10px !important;
            }

            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #5e5e5e;
          }
        }
      }
    }

    .col-md-8 {
      background-color: #f6f7f8;
      max-width: 941px;
      min-width: 60%;
      width: 100%;
      height: 520px;
      position: absolute;
      right: 0;
      padding: 80px 0 0 168px;

      @media screen and (max-width: 1024px) {
        width: 65%;
        padding: 80px 0 0 14px;
      }

      @include breakpoints(medium) {
        padding: 80px 0 0 50px;
      }

      @media screen and (max-width: 767px) {
        padding: 42px 0 0 24px;
        position: initial;
        height: 267px;
        width: 100%;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      .champ-img {
        max-width: 100%;
        width: 100%;
        height: 461px;
        background: url("../assets/images/rugby-challenge.jpg");
        // background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/rugby-challenge.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-attachment: inherit;

        @include breakpoints(large) {
          background-position: 40px 0;
        }

        @include breakpoints(medium) {
          background-position: -50px 0;
        }

        @media screen and (max-width: 767px) {
          height: 300px;
          background: url("../assets/images/mobile.jpg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          background-attachment: inherit;
        }
      }
    }
  }
}

.placeigadd {
  padding: 0px 10px 0;
  background-color: white;

  @media screen and (max-width: 1210px) {
    background-color: #f6f6f6;
    padding: 30px 0px 5px;
    margin-top: 24px;
    position: relative;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  @media screen and (min-width: 1600px) {
    text-align: center;
    justify-content: left;
    display: block;
    float: left;
  }

  margin: 0px 0 24px;

  .place-b {
    text-align: center;
    justify-content: center;
    display: flex;
  }
}

.userh {
  text-align: center;
  justify-content: center;
  display: flex;

  @media screen and (max-width: 1210px) {
    display: none;
  }
}

.modal-backdrop {
  &.show {
    z-index: 100000;
  }
}

.modal-open {
  .modal {
    z-index: 100000;
  }
}

.dropdown {
  .btn-success {
    background-color: transparent !important;
    border-color: transparent !important;
    color: #14376f !important;
  }

  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    font-size: 18px;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  .btn-success.dropdown-toggle:focus {
    box-shadow: none !important;
  }

  .btn {
    padding: 0;
    font-family: $mirpuri-book;
    font-size: 15px;
    font-weight: normal;
    outline: none !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #14376f;

    span {
      padding: 0;

      img {
        padding-right: 10px;
        width: 25px;
        padding-bottom: 5px;
      }
    }
  }

  .dropdown-menu {
    max-width: 174px;
    width: 100%;
    padding: 10px 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

    a {
      padding: 5px 0;
    }

    .dropdown-item:hover,
    .dropdown-item:focus {
      color: #14376f !important;
      text-decoration: none;
      background-color: transparent !important;
    }

    .midline {
      width: 100%;
      padding: 0 5px;
      margin: 5px 0 10px;
      background-color: #80808017;
      height: 1px;
    }
  }

  .dropdown-menu::after,
  .dropdown-menu::before {
    content: "";
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 100%;
    height: 1em;
    width: 1em;
    transform-origin: center center;
    transform: translate(-2em, -0.5em) rotate(45deg);
    z-index: -1;
  }

  .dropdown-menu::before {
    z-index: 1;
    box-shadow: none;
  }
}

.form-inline {
  .dropdown {
    @media screen and (width: 768px) {
      display: inline-flex !important;
    }
  }
}

select {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  border-radius: 5px;
  height: 34px;
  background: url(https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/faq-down.svg)
    no-repeat right #ddd;
  -webkit-appearance: none;
  background-position-x: 95%;
}

.error {
  color: red;
  font-family: $mirpuri-book;
  font-size: 14px !important;
}

.succesmsg {
  color: green;
  font-size: 16px;
}

.err-paragraph {
  font-size: 14px !important;
  padding-top: 5px !important;
}

.err-gender {
  color: red;
  font-family: $mirpuri-book;
  font-size: 14px !important;
  padding-top: 0px;
}

.form-bg-color {
  background-color: white !important;
  color: black !important;
}

.card-reverse {
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse !important;
  }
}

.teamparent {
  background-color: #f6f6f6;
  text-align: center;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 767px) {
    overflow: hidden;
  }
}

.leaderbord50 {
  width: 50%;

  @media screen and (max-width: 1366px) {
    width: 70%;
  }

  @media screen and (max-width: 1200px) {
    width: 100% !important;
  }
}

.teamparent {
  .leaderbord100 {
    padding-right: 3.5vw;
    padding-left: 3.5vw;

    @media screen and (min-width: 1441px) {
      padding-right: 3.5vw;
      padding-left: 3.5vw;
    }

    @media screen and (max-width: 500px) {
      padding: 13px 20px !important;
    }
  }
}

.leaderbord100 {
  width: 100%;
}

.readmemore {
  a {
    font-size: 15px;
  }

  margin-top: -15px;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: inherit;
}

.modal-open {
  margin-right: 0 !important;
}

.headerAdvt {
  display: none !important;

  .smallBanner {
    display: none;
  }

  margin: 10px 0px 0;
  height: 117px;

  @media screen and (max-width: 1100px) {
    height: auto;

    .bigBanner {
      width: 100%;
    }
  }

  @media screen and (max-width: 500px) {
    .bigBanner {
      display: none;
    }

    .smallBanner {
      display: block;
      margin: 0 auto;
    }

    margin-bottom: 20px;
    display: block;
    height: 112px;
  }
}

.outer-pdf {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  width: 100%;
  height: 600px;
}

.desktop-pdf {
  display: none;
}

.mobile-pdf {
  display: block;
}

@media only screen and (min-width: 1025px) {
  .mobile-pdf {
    display: none;
  }

  .desktop-pdf {
    display: block;
  }
}

.watchBanners {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;

  a {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 20px;
    color: #000000;
    font-size: 20px;
  }

  img {
    width: 300px;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }

    height: auto;
    margin-bottom: 10px;
  }
}

.Maincontent.share-score-wrap {
  padding-top: 0;
}

.Maincontent {
  &.admin-container {
    padding-top: 100px;
  }
}

.Maincontent {
  &.share-leaderboard-wrap {
    background-image: url("#{$imagePath}bgimg.jpg");
    padding-top: 30px;

    @media only screen and (max-width: 500px) {
      top: 0;
      padding-top: 15px !important;
    }

    .invite-inner-right {
      display: none;
    }

    .invite-inner-left {
      width: 100%;
    }
  }
}
