.Profile-banner .profile-hero {
  width: 100%;
  height: 200px;
  position: relative;
}
.privacypolicy {
  .deatilstext .textandadd .Aboutus {
    max-width: 100% !important;
    width: 100%;
  }
}
.deatilstext .textandadd {
  margin-bottom: 40px;
  margin-top: 40px;
  max-height: 15000px;
  height: 100%;
}
.Profile-banner p {
  position: absolute;
  top: 40% !important;
}
.Profile-banner p {
  position: absolute;
  top: 40%;
  left: 9vw;
  @media screen and (max-width: 500px) {
    left: 7vw !important;
  }
  font-family: "industry-Medium";
  font-size: 33px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: var(--white);
}
.about_us {
  .deatilstext {
    padding-bottom: 0px;
  }
  .Profile-banner .profile-hero {
    width: 100%;
    height: 200px;
    position: relative;
  }
  .deatilstext .textandadd {
    margin-bottom: 40px;
    @media screen and (max-width: 1000px) {
      margin-bottom: 0px;
    }

    margin-top: 40px;
    max-height: 15000px;
    height: 100%;
  }
  .Profile-banner p {
    position: absolute;
    top: 40%;
    left: 9vw;
    font-family: "industry-Medium";
    font-size: 33px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    color: var(--white);
  }
}
.Profile-banner {
  background-color: #eeeeee;
  .profile-hero {
    @media screen and (max-width: 700px) {
      height: 182px;
      margin-bottom: 100px;
    }
  }

  .profile-hero img {
    width: 100%;
    height: 100%;
    position: relative;
    // @media screen and (max-width: 1350px) {
    //   height: 100%;
    // }
    @media screen and (max-width: 1000px) {
      height: 100%;
    }
  }
  p {
    position: absolute;
    top: 32%;
    left: 9vw;
    @media screen and (max-width: 1300px) {
      top: 45%;
    }
    @media screen and (max-width: 767px) {
      height: 50% !important;
      top: 25%;
      left: 20px;
      font-size: 26px;
    }
    font-family: "industry-Medium";
    font-size: 33px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    color: var(--white);
  }
}
.placeleaderbox {
  text-align: center;
}
.deatilstext {
  .place-b {
    @media screen and (max-width: 100px) {
      text-align: center;
      justify-content: center;
      display: flex;
    }
    @media screen and (max-width: 1039px) {
      padding: 20px 20px 0;
      justify-content: center;
      display: flex;
      .place-lo {
        margin: 0px !important;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 20px 20px 0;
      justify-content: center;
      display: flex;
      .place-lo {
        margin: 0px !important;
        max-width: 355px;
      }
    }
  }
  .container {
    max-width: 1240px;
    @media screen and (max-width: 700px) {
      margin-top: 20px;
      padding: 0 10px;
    }
    @media screen and (max-width: 1440px) {
      max-width: 1200px;
    }
  }

  @media screen and (max-width: 700px) {
    background-color: rgb(238, 238, 238);
    margin-top: -100px;
  }
  .Profile-banner {
    background-color: rgb(238, 238, 238);
  }
  .textandadd {
    margin-bottom: 40px;
    max-height: 15000px;
    @media screen and (max-width: 1000px) {
      max-height: 100%;
      margin-bottom: 0px;
    }
    @media screen and (max-width: 767px) {
      max-height: 100%;
      margin-bottom: 10px;
      margin-top: 50px;
    }
    height: 100%;
    .Aboutus {
      .table-scroll-points .points-total {
        @media screen and (max-width: 400px) {
          font-size: 15px !important;
        }
      }
      .table-scroll-points .table th:nth-child(1) {
        width: 20% !important;
        text-align: left;
        font-size: 14px;
        @media screen and (max-width: 700px) {
          font-size: 10px;
          padding: 10px 2px;
        }
        @media screen and (max-width: 400px) {
          width: 40% !important;
        }
      }
      .table-scroll-points .table th:nth-child(2) {
        width: 15% !important;
        text-align: center;
        font-size: 14px;
        @media screen and (max-width: 700px) {
          font-size: 10px;
          padding: 10px 2px;
        }
        @media screen and (max-width: 400px) {
          width: 12% !important;
        }
      }
      .table-scroll-points .table th:nth-child(3) {
        width: 15% !important;
        text-align: center;
        font-size: 14px;
        @media screen and (max-width: 700px) {
          font-size: 10px;
          padding: 10px 2px;
        }
        @media screen and (max-width: 400px) {
          width: 14% !important;
        }
      }
      .table-scroll-points .table th:nth-child(4) {
        width: 13%;
        text-align: center;
        font-size: 14px;
        @media screen and (max-width: 7700px) {
          width: 15%;
        }
        @media screen and (max-width: 700px) {
          font-size: 10px;
          padding: 10px 2px;
        }
        @media screen and (max-width: 400px) {
          width: 15% !important;
        }
      }
      .table-scroll-points .table th:nth-child(5) {
        width: 20% !important;
        text-align: center;
        font-size: 14px;
        @media screen and (max-width: 700px) {
          font-size: 10px;
          padding: 10px 2px;
        }
        @media screen and (max-width: 400px) {
          width: 14% !important;
        }
      }
      .table-scroll-points .table td:nth-child(1) {
        width: 20%;
        text-align: left;
        padding-left: 0;
        @media screen and (max-width: 700px) {
          font-size: 10px;
          padding: 10px 2px;
        }
        @media screen and (max-width: 400px) {
          width: 40% !important;
        }
      }
      .table-scroll-points .table td:nth-child(2) {
        width: 15%;
        text-align: center;
        padding-right: 20px;
        @media screen and (max-width: 700px) {
          font-size: 10px;
          padding: 10px 2px;
        }
        @media screen and (max-width: 400px) {
          width: 12% !important;
        }
      }
      .table-scroll-points .table td:nth-child(3) {
        width: 15%;
        text-align: center;
        @media screen and (max-width: 700px) {
          font-size: 10px;
          padding: 10px 2px;
        }
        @media screen and (max-width: 400px) {
          width: 14% !important;
        }
      }
      .table-scroll-points .table td:nth-child(4) {
        width: 15%;
        text-align: center;
        @media screen and (max-width: 700px) {
          font-size: 10px;
          padding: 10px 2px;
        }
        @media screen and (max-width: 400px) {
          width: 15% !important;
        }
      }
      .table-scroll-points .table td:nth-child(5) {
        width: 20%;
        text-align: center;
        @media screen and (max-width: 7700px) {
          width: 20%;
        }
        @media screen and (max-width: 700px) {
          font-size: 10px;
          padding: 10px 2px;
        }
        @media screen and (max-width: 400px) {
          width: 14% !important;
        }
      }

      display: inline-block;
      @media screen and (max-width: 1305px) {
        max-width: 790px;
        width: 100%;
        margin-left: 20px;
      }
      @media screen and (max-width: 1200px) {
        max-width: 650px;
        width: 100%;
      }
      @media screen and (max-width: 1039px) {
        max-width: 100% !important;
        width: 100%;
        margin: 0;
      }
      @media screen and (max-width: 1024px) {
        margin-left: 0px;
      }
      @media screen and (max-width: 1000px) {
        display: block;
        width: 100%;
        height: auto;
      }

      max-width: 812px;
      width: 100%;
      padding: 33px 36px;
      height: auto;

      .blackpad {
        padding-bottom: 0.5rem;
      }
      .greenpad {
      }
    }
    .img-box1 {
      display: inline-block;
      @media screen and (max-width: 1039px) {
        display: block;
        position: initial;
        max-width: 100% !important;
        margin-top: 20px;
        margin-bottom: 31px;
        margin-left: 0px;
      }
      max-width: 338px;
      @media screen and (max-width: 1440px) {
        max-width: 338px;
      }
      width: 100%;
      margin-left: 31px;
      position: relative;
      vertical-align: top;
      .img-box {
        height: 290px;
        .img {
          background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/invite-right-img.jpg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          background-attachment: inherit;
          display: inline-flex;
        }
      }
    }
  }
  .Aboutus {
    @media screen and (max-width: 1000px) {
      padding: 20px 23px 31px 23px;
    }
    @media screen and (max-width: 767px) {
      padding: 20px !important;
    }
    background-color: white;
    height: auto;

    p {
      width: 100%;
      height: auto;
      font-family: $mirpuri-book;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: 0.5px;
      color: #333333;
    }
    .p-green-bold {
      font-weight: bold;
      color: #4d964c;
      margin-bottom: 0rem;
      font-family: "MaisonNeue-Bold";
      font-size: 17px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: 0.5px;
    }
    .p-black-bold {
      font-family: $mirpuri-book;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.73;
      letter-spacing: 0.54px;
      color: #333333;
    }
  }

  .img-box {
    background-color: white;
    padding: 20px 19px;
    text-align: center;
    .img {
      max-width: 300px;
      width: 100%;
      height: 250px;
      background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/new/placeholder2.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-attachment: inherit;
      display: inline-flex;
    }
  }

  .Faqs {
    padding: 32px 17px 35px 30px;
    background-color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    .accordion__section_main {
      .accordion_chevron2 {
        margin-top: -68px !important;
        @media screen and (max-width: 1039px) {
          margin-top: -38px !important;
        }
        @media screen and (max-width: 500px) {
          margin-top: -42px !important;
        }
        @media screen and (max-width: 400px) {
          margin-top: -68px !important;
        }
      }

      .accordion_chevron {
        margin-top: -40px;
        @media screen and (max-width: 350px) {
          margin-top: -65px !important;
        }
        .accordion__icon {
          background: url(https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/+.svg);
          background-repeat: no-repeat;
          background-position: 50%;
          .path {
            display: none !important;
          }
        }
        .rotate {
          background: url(https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/minus.png) !important;
          background-repeat: no-repeat;
          background-position: 50%;
          transform: none;
          transition: none;
          width: 5px;
        }
      }
      margin: 0;
      border: none;
      padding: 0;
      .accordion_heading {
        margin: 2px 371px 10px 0;
        font-family: "MaisonNeue";
        font-size: 20px;
        @media screen and (max-width: 300px) {
          font-size: 18px;
        }
        width: 95%;
        @media screen and (max-width: 767px) {
          width: 90%;
        }
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: #000;
      }
      .accordion_content {
        font-family: "MaisonNeue";
        font-size: 13px;
        font-weight: 400;
        margin: 0;
        font-stretch: normal;
        height: 250px;
        @media screen and (max-width: 300px) {
          font-size: 11px;
        }
        font-style: normal;
        line-height: 1.62;
        letter-spacing: normal;
        color: #616161;
        padding: 16px 8px 0 0;
        text-align: left;
      }
    }
    .header {
      padding: 0 0;
    }

    p {
      h3 {
        @include heading-faq;
        display: inline-block;
      }
      .leftfaq {
        font-family: $mirpuri-book;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        letter-spacing: 0.86px;
        color: #14376f;
        float: left;
      }
      .rightviewall {
        float: right;
        .btn-viewall {
          border: none;
          text-decoration: underline;
          background-color: transparent;
          outline: none;
          font-family: $industry-Medium;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          padding-right: 0px;
          line-height: normal;
          letter-spacing: 0.75px;
          color: #14376f;
        }
      }
    }

    .accordions {
      margin-top: 19px;

      .rotate {
        transform: none !important;
        background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/-.svg") !important;
        background-repeat: no-repeat;
        background-position: center;
      }

      .accordion__icon {
        background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/+.svg");
        background-repeat: no-repeat;
        background-position: center;

        path {
          display: none;
        }
      }

      .accordion__title {
        font-family: $mirpuri-book;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: #000000;
      }
      .accordion__content {
        background-color: white;
        overflow: hidden;
        transition: max-height 0.6s ease;
        border-right: none !important;
        border-bottom: none !important;
        border-left: none !important;

        .accordion__text {
          font-family: $mirpuri-book;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.62;
          letter-spacing: normal;
          color: #616161;
          padding: 16px 8px 0px 0px;
          text-align: left;
        }
      }

      .accordion {
        border-top: none !important;
        border-right: none !important;
        border-left: none !important;
        background-color: white;
        height: 46px;
        color: #444;
        cursor: pointer;
        padding: 18px 0;
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        transition: background-color 0.6s ease;
      }
    }
  }
}
ul {
  padding: 0 15px !important;
  li {
    width: 100%;
    height: auto;
    font-family: $roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: 0.5px;
    color: #333333;
  }
}
ol {
  padding: 0 10px !important;
  li {
    width: 100%;
    height: auto;
    font-family: "MaisonNeue";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: 0.5px;
    color: #333333;
  }
}
@media screen and (min-width: 800px) {
  .profile-hero-mobile {
    display: none;
  }
  .profile-hero-pc {
    display: block;
  }
}
@media screen and (max-width: 800px) {
  .profile-hero-pc {
    display: none;
  }
  .profile-hero-mobile {
    display: block;
  }
}
