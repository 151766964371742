.sticky-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
}
.navbar-nav{
  align-items: center;
}

.bg-bright {
  background-color: white !important;  
  padding-right: 5vw;
  padding-left: 9vw;  
  padding-top:32px;
  padding-bottom:32px;
  margin-bottom: 0;
  @media screen and (min-width: 1300px) {
    padding-right: 5vw;
    padding-left: 9vw;
  }
  @media screen and (max-width: 1200px) {
    padding-right: 2vw;
    padding-left: 5vw;
  }
  @media screen and (max-width: 1070px) {
    padding-right: 0%;
    padding-left: 1vw;
  }
  @media screen and (max-width:1023px){
    padding-right: 1vw;
    padding-top:10px;
    padding-bottom:10px;
  }
  @media screen and (max-width: 500px) {
    padding-right: 0 !important;
    padding-left: 0 !important;
    height: 72px !important;
    margin-bottom: 0 !important;
    padding-top: 5px !important;
  }
  @media screen and (min-width: 1001px) {
    justify-content: center;
    // padding-right: 5vw;
  }

  .usericon {
    display: none;
    @media screen and (max-width: 1000px) {
      display: block;
      padding: 0px;
      width: 30% !important;
      float: right !important;
      text-align: right !important;
    }
  }
}

.navbar-toggler {
  padding-left: 0;
  margin-right: 19%;
  @media screen and (max-width: 300px) {
    margin-right: 5% !important;
  }
  @media screen and (max-width: 330px) {
    margin-right: 14%;
  }
  @media screen and (max-width: 1200px) {
    display: none;
  }
  @media screen and (max-width: 1000px) {
    display: block !important;
  }
  height: 50px;
  span {
    background-image: url(https://d7le4ielk3ho1.cloudfront.net/src_images/new/hamburger.svg) !important;
  }
}

.navuser {
  display: none;
}
@media screen and (max-width: 1000px) {
  .pad-right {
    height: 50px;
    padding-right: 0;
  }
  .navuser {
    display: block;
  }
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  @media screen and (max-width: 1000px) {
    width: 30% !important;
  }

  padding-bottom: 0.3125rem;
  margin-right: 0;
  margin-left: 0rem;
  @media screen and (max-width: 1160px) {
    margin-left: 0;
  }
  @media screen and (max-width: 7670px) {
    margin-left: 0rem !important;
  }
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-bright .navbar-brand:hover,
.navbar-bright .navbar-brand:focus {
  color: #fff;
}

.navbar-bright .navbar-brand {
  color: #454545;
}
@media screen and (max-width: 1000px) {
  .form-inline {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
  }
}
.form-inline {
  span:hover {
    border-bottom: 1px solid #14376f !important;
  }
}
.form-inline {
  margin-top: 5px !important;
  margin-right: 5px;
  display: inline-table;
  flex-flow: row wrap;
  vertical-align: middle;
  align-items: center;
  width: auto !important;
  justify-content: center;
  @media screen and (max-width: 1100px) {
    width: auto;
  }
  @media screen and (max-width: 800px) {
    width: auto;
    padding-top: 3px;
    text-align: left;
    margin: 0 !important;
  }
  @media screen and (max-width: 768px) {
    width: auto !important;

    text-align: left;
    display: inline;
    margin-right: 10px !important;
  }

  a {
    height: 34px;
    color: #14376f;
    text-decoration: none;
    background-color: transparent;
    font-family: $mirpuri-book;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #14376f;
  }
  .nav-link {
    display: block;
    padding: 0.5rem 0;
  }
  span {
    color: #14376f;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 1px;
    font-size: 15px;
  }
  img {
    padding-right: 5px;
    width: 21px;
    padding-bottom: 8px;
    @media screen and (max-width: 800px) {
      padding-bottom: 8px;
      width: 18px;
    }
    @media screen and (max-width: 1000px) {
      padding-bottom: 9px !important;
    }
  }
}

.navbar-dark .navbar-nav .nav-link {
  padding-right: 2%;
  padding-left: 2%;

  font-size: 15px;
  font-weight: normal;
  @media screen and (max-width: 800px) {
    text-align: center;
  }
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #454545;
  font-family: $mirpuri-book;
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: #14376f;
  font-family: $mirpuri-bold;
  font-weight: 700;
}
.navbar-dark .navbar-nav .nav-link:hover {
  span {
    border-bottom: 1px solid #14376f !important;
  }
}
.crclog {
  text-align: center;
  img {
    width: 100px !important;
  }
  @media screen and (max-width: 1000px) {
    img {
      width: 57px !important;
    }
  }
}
.navlogo {
  position: absolute;
  top:23px;
  left: 5vw;
  @media screen and (max-width: 1200px) {
    left: 1vw;
  }
  @media screen and (max-width: 1000px) {
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: fit-content;
    top: 10px;
  }
  img {
    width: 100px;
    @media screen and (max-width: 1000px){
      width: 85px;
    }
  }
  @media screen and (max-width: 500px) {
    top:6px;
    img {
      width: 65px;
    }
  }
}
.navbar-expand-lg .navbar-nav {
  flex-direction: row-reverse;
  width: 100%;

  margin-right: 1.5% !important;
  border: none !important;
}
@media screen and (min-width: 1250px) {
  .navbar-expand-lg .navbar-nav {
    justify-content: right !important;
  }
}
@media screen and (max-width: 825px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: row-reverse !important;
    width: 100% !important;
    display: inline-flex;
    justify-content: center;
    margin-right: 0.6% !important;
  }
}
@media screen and (max-width: 770px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: row-reverse !important;
    width: 100% !important;
    display: inline-flex;
    justify-content: center;
    margin-right: 0.6% !important;
  }
}
@media screen and (width: 768px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: row-reverse !important;
    width: 100% !important;
    display: inline-flex;
    justify-content: center;
    margin-right: 0.6% !important;
  }
}
@media screen and (width: 1024px) {
  .navbar-expand-lg .navbar-nav {
    margin-right: 1% !important;
  }
}
@media screen and (max-width: 755px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: row-reverse !important;
    width: 100% !important;
    display: inline-flex;
    justify-content: center;
    margin-right: 0.6% !important;
  }
}
@media screen and (max-width: 725px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: row-reverse !important;
    width: 100% !important;
    display: inline-flex;
    justify-content: center;
    margin-right: 0.6% !important;
  }
}

@media screen and (max-width: 1210px) {
  .navbar-dark .navbar-nav .nav-link {
    padding-right: 1% !important;
    padding-left: 1% !important;
  }
}
@media screen and (max-width: 720px) {
  .navbar-dark .navbar-nav .nav-link {
    padding-right: 0.5% !important;
    padding-left: 0.5% !important;
  }
}
@media screen and (max-width: 1238px) {
  .navbar-dark .navbar-nav .nav-link {
    padding-right: 1, 5%;
    padding-left: 1.5%;
  }
}
.mr-auto,
.mx-auto {
  margin-right: 0 !important;
}

.basic-navbar-nav {
  display: none !important;
}

.navcolpsbtn {
  margin: 0 !important;
  padding: 0;
  // width: 30% !important;
}
.navmobilebuton {
  .basic-navbar-nav {
    @media screen and (max-width: 1000px) {
      display: block !important;
    }
  }
}

.opensidenav {
  display: none;
  .socialmedia {
    padding-left: 17px !important;
    .nav-link {
      padding: 0rem 8px !important;
      img {
        width: 50px;
      }
      img {
        @media screen and (max-width: 300px) {
          width: 30px;
        }
      }
    }
  }
}
.closesidenav {
  display: none;
}

.navbar-nav {
  .active {
    padding-right: 2%;
    padding-left: 2%;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #14376f !important;
    font-family: $mirpuri-bold !important;
    font-weight: 700 !important;
  }
}
.navbar-collapse {
  .livescorewrap {
    display: inline;
    text-align: center;
    justify-content: center;
    width: 100%;
  }
}
@media (min-width: 1001px) {
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    // padding: 0px 1px 0px 174px;
    overflow: visible !important;
    // .disable-link{
    //   pointer-events: none;
    // }
  }
}
@media (max-width: 1000px) {
  .navbar-collapse.collapse {
    padding-right: 0vw;
    padding: 0;
    display: none !important;
  }
}
@media (max-width: 750px) {
  .navbar-collapse.collapse {
    padding: 0;
  }
}
@media (width: 768px) {
  .navbar-collapse.collapse {
    padding-right: 0vw !important;
  }
}

@media screen and (max-width: 1000px) {
  .opensidenav {
    .btn-livescore {
      width: 60%;
      position: relative;
      margin-left: 10px;
      padding: 0.775rem 0.75rem;
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 15px;
    }
    display: block;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    background-color: #000000bf;
    overflow-x: hidden;
    transition: 0.5s;

    .social {
      background-color: white;
      margin-top: -20px;
    }

    p {
      font-family: $mirpuri-book;
      font-size: 15px;
      font-weight: normal;
      padding-left: 28px;
      font-stretch: normal;
      font-style: normal;
      line-height: 3.8;
      margin: 0;
      letter-spacing: 0.94px;
      color: #4a4949;
    }

    .closebutton {
      position: absolute;
      @media screen and (max-width: 350px) {
        width: 90%;
      }
      @media screen and (max-width: 300px) {
        width: 85%;
      }
      width: 100%;
      text-align: right;

      .btn-clodebtn {
        margin: 10px;
        padding: 0.75rem 0.75rem;
        width: 40px;
        color: #1e1e1e;
      }
    }

    .sidenavbar {
      position: relative;
      width: 329px;
      height: 100%;
      background-color: white;
      z-index: 9999999;
      display: inline-table;

      .midline {
        opacity: 0.52;
        background-color: #979797;
        height: 1px;
        margin: 32px 25px;
        @media screen and (max-width: 450px) {
          margin: 2px 25px;
        }
        width: 80%;
      }

      .secondmidline {
        opacity: 0.52;
        background-color: #979797;
        height: 1px;
        margin: 100px 20px 13px 27px;
        @media screen and (max-width: 450px) {
          margin: 5px 20px 20px 27px !important;
        }
      }

      .sidenavlink {
        padding-left: 11px;

        .active {
          color: #14376f !important;
          font-family: $roboto-Condensed !important;
        }

        .nav-link {
          display: block;
          width: 100%;
          font-family: $mirpuri-book;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.5;
          letter-spacing: 0.94px;
          color: #454545;
        }
      }
    }
  }
  .navbar-brand {
    padding: 5px !important;
  }
  .closesidenav {
    -webkit-transform: translate3d(-100%, 0px, 0px);
    width: 0;
    display: block;
  }
  @media screen and (max-width: 1000px) {
    .closesidenav {
      width: 0;
      display: none !important;
    }
  }
}
.colapsedropdownmenu {
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    font-size: 18px;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .btn-success.dropdown-toggle:focus {
    box-shadow: none !important;
  }
  .btn {
    padding: 0;
    font-family: $mirpuri-book !important;
    font-size: 15px;
    font-weight: normal;
    outline: none !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #14376f;
    span {
      padding: 0;
      img {
        padding-right: 10px;
        width: 26px !important;
        padding-bottom: 10px !important;
      }
    }
  }
  .dropdown-menu {
    max-width: 174px;
    width: 100%;
    padding: 10px 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    top: 100%;
    left: -10px;
    @media screen and (max-width: 1000px) {
      top: 125%;
      left: auto;
      right: -10px;
    }
    @media screen and (max-width: 300px) {
      left: -100% !important;
    }
    a {
      padding: 5px 0;
      color: #14376f !important;
      text-decoration: none;
      background-color: transparent !important;
    }
    .dropdown-item:hover,
    .dropdown-item:focus {
      color: #14376f !important;
      text-decoration: none;
      background-color: transparent !important;
    }
    .midline {
      width: 100%;
      padding: 0 5px;
      margin: 5px 0 10px;
      background-color: #80808017;
      height: 1px;
    }
  }
  .dropdown-menu::after,
  .dropdown-menu::before {
    content: "";
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 100%;
    height: 1em;
    width: 1em;
    transform-origin: center center;
    transform: translate(-2em, -0.5em) rotate(45deg);
    z-index: -1;
  }

  .dropdown-menu::before {
    z-index: 1;
    box-shadow: none;
  }
}
.navdropbtn {
  img {
    padding-right: 10px;
    width: 26px;
    padding-bottom: 10px;
  }
  .btn-primary {
    color: grey;
    background-color: transparent;
    border-color: transparent !important;
    font-size: 15px;
    font-family: $roboto-Condensed !important;
    font-weight: 700;
  }
  .show > .btn-primary.dropdown-toggle {
    color: #14376f;
    background-color: transparent;
    border-color: transparent;
    outline: none;
  }
  .btn {
    display: inline-block;
    font-weight: 700;
    font-size: 15px;
    font-family: $roboto-Condensed !important;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: none;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

.dropdown {
  .btn-success {
    background-color: transparent !important;
    border-color: transparent !important;
    color: #14376f !important;
    @media screen and (max-width: 1000px) {
      display: inline-flex !important;
    }
  }
  .dropdown-toggle::after {
    @media screen and (max-width: 1000px) {
      display: none !important;
    }
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    font-size: 18px;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .btn-success.dropdown-toggle:focus {
    box-shadow: none !important;
  }
  .btn {
    padding: 0;

    @media screen and (max-width: 1000px) {
      padding-bottom: 3px !important;
    }
    font-family: $mirpuri-book !important;
    font-size: 15px;
    font-weight: normal;
    outline: none !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #14376f;
    span {
      padding: 0;
      @media screen and (max-width: 800px) {
        font-size: 16px;
        padding: 0px 5px;
        vertical-align: bottom;
      }
      img {
        width: 24px !important;
        padding-right: 10px;
        padding-bottom: 6px !important;
        @media screen and (max-width: 800px) {
          padding-bottom: 5px !important;
          width: 25px !important;
        }
        @media screen and (max-width: 1000px) {
          padding-bottom: 9px !important;
          width: 24px !important;
        }
      }
    }
  }
  .dropdown-menu {
    max-width: 174px;
    width: 100%;
    padding: 10px 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

    a {
      padding: 5px 0;
    }
    .dropdown-item:hover,
    .dropdown-item:focus {
      color: #14376f !important;
      text-decoration: none;
      background-color: transparent !important;
    }
    .midline {
      width: 100%;
      padding: 0 5px;
      margin: 5px 0 10px;
      background-color: #80808017;
      height: 1px;
    }
  }
  .dropdown-menu::after,
  .dropdown-menu::before {
    content: "";
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 100%;
    height: 1em;
    width: 1em;
    transform-origin: center center;
    transform: translate(-2em, -0.5em) rotate(45deg);
    z-index: -1;
  }

  .dropdown-menu::before {
    z-index: 1;
    box-shadow: none;
  }
}
@media (max-width:"768px") {
  
.bracket-btn{
  width: auto !important;
}
}
.btn-livescore {
  border-right: 1px solid #4d964c;
  border-left: 1px solid #14376f;
  background-image: linear-gradient(to right, #14376f, #4d964c);
  border-radius: 0;
  font-family: $roboto-Condensed;
  text-transform: uppercase;
  font-size: 16px;
  @media screen and (max-width: 1200px) {
    font-size: 12px;
  }
  width: 15%;
  color: white;
  font-stretch: normal;
  font-style: normal;
}
.btn-livescore:hover {
  color: white;
}
.btn-livescore:active {
  background-image: none !important;
  outline: 0;
  border: none;
  color: white !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.btn-livescore:focus {
  color: white !important;
  text-decoration: none;
}
.dropdown-menu {
  @media screen and (max-width: 1280px) {
    left: 8%;
  }
}
.dropdown-menu {
  left: auto;
  right: -20px;
  top: 32px;
  @media screen and (max-width: 500px) {
    left: -45px !important;
  }
}
.navbarmideline {
  width: 1px;
  position: relative;
  margin-right: 2%;
  @media screen and (max-width: 1100px) {
    margin-right: 1.5%;
  }
  @media screen and (max-width: 800px) {
    margin-right: 1%;
  }
  background-color: #979797;
  margin-top: 4px;
  margin-bottom: 7px;
  vertical-align: middle;
}
.navbar-nav .dropdown-menu {
  position: absolute !important;
  float: none;
}
#afterloginnav {
  @media screen and (max-width: 1256px) {
    .nav-link {
      padding-right: 1%;
      padding-left: 1%;
    }
  }
  @media screen and (max-width: 769px) {
    .nav-link {
      padding-right: 0.5% !important;
      padding-left: 0.5% !important;
    }
  }
  @media screen and (max-width: 727px) {
    .nav-link {
      padding-right: 0.1% !important;
      padding-left: 0.1% !important;
    }
  }
}
