// $imagePath:'http://localhost:3000/assets/images/';
$imagePath:'./images/';
// $imagePath:'https://stage.maymadness7s.com/assets/images/';
// $imagePath:'https://brackets.maymadness7s.com/assets/images/';
$breakpoints: ( small: 767px, medium: 990px, large: 1140px, xLarge:1300px);
$breakpoint-classes: ( small medium large xLarge);
@mixin size($width, $height: $width) {
    height: $height;
    width: $width;
}

@mixin text-hide {
    background-color: transparent;
    border: 0;
    color: transparent;
    text-shadow: none;
} //Truncating the text, elipsis by default.
//
@mixin text-truncate {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
} //Font icons
//
@mixin icon($position: before, $icon: false, $styles: true) {
    @if $position==both {
        $position: 'before, &:after';
    }
    &:#{$position} {
        // background: -webkit-linear-gradient(50deg, $purple-100 0%, $blue-100 100%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        @if $icon {
            // A particular icon has been specified
            content: '#{map-get($icons, $icon)}';
        }
        @if $styles {
            // Supportive icon styles required
            font-family: $font-icon;
        } // Include any extra rules supplied for the pseudo-element
        @content;
    }
}

@mixin font-smoothing {
    transform: none;
}

@mixin transition($property, $duration: .25s, $timing: ease-out) {
    transition: $property $duration $timing;
}

@mixin layer($zindex: 1) {
    height: 100%;
    #{$global-left}: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: $zindex;
}

@mixin center-block {
    display: block;
    margin-#{$global-right}: 0;
    margin-#{$global-left}: 0;
}

@mixin full-width-image {
    display: block;
    height: auto;
    max-width: 100%;
    width: 100%;
}

@mixin border($width: 1px, $type: solid, $color: $gray-600, $side: all, $radius: false) {
    @if $side=='all' and $radius==true {
        border: $width $type $color;
        border-radius: 3px;
    }
    @else if $side=='all' {
        border: $width $type $color;
    }
    @else {
        border-#{$side}: $width $type $color;
    }
}

@mixin border-radius($radius: $global-radius) {
    border-radius: $radius;
}

@mixin vertical-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@mixin horizontal-center {
    #{$global-left}: 50%;
    position: absolute;
    transform: translateX(-50%);
}

@mixin absolute-center {
    #{$global-left}: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin full-width-image {
    display: block;
    height: auto;
    max-width: 100%;
    width: 100%;
}

/* mixin for multiline */

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
    overflow: hidden;
    position: relative;
    max-height: $lineHeight * $lineCount;
    margin-right: -1em;
    padding-right: 1em;
    &:before {
        content: '...';
        position: absolute;
        #{$global-right}: 0;
        bottom: 0;
    }
    &:after {
        content: '';
        position: absolute;
        #{$global-right}: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: $bgColor;
    }
}

// Lightbox mixins
// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden`
// Vendor Prefixes
//
// All vendor mixins are deprecated as of v3.2.0 due to the introduction of
// Autoprefixer in our Gruntfile. They will be removed in v4.
// - Animations
// - Backface visibility
// - Box shadow
// - Box sizing
// - Content columns
// - Hyphens
// - Placeholder text
// - Transformations
// - Transitions
// - User Select
// - cursor grab
// Animations
@mixin animation($animation) {
    -webkit-animation: $animation;
    -o-animation: $animation;
    animation: $animation;
}

@mixin animation-name($name) {
    -webkit-animation-name: $name;
    animation-name: $name;
}

@mixin animation-duration($duration) {
    -webkit-animation-duration: $duration;
    animation-duration: $duration;
}

@mixin animation-timing-function($timing-function) {
    -webkit-animation-timing-function: $timing-function;
    animation-timing-function: $timing-function;
}

@mixin animation-delay($delay) {
    -webkit-animation-delay: $delay;
    animation-delay: $delay;
}

@mixin animation-iteration-count($iteration-count) {
    -webkit-animation-iteration-count: $iteration-count;
    animation-iteration-count: $iteration-count;
}

@mixin animation-direction($direction) {
    -webkit-animation-direction: $direction;
    animation-direction: $direction;
}

@mixin animation-fill-mode($fill-mode) {
    -webkit-animation-fill-mode: $fill-mode;
    animation-fill-mode: $fill-mode;
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden`
@mixin backface-visibility($visibility) {
    -webkit-backface-visibility: $visibility;
    -moz-backface-visibility: $visibility;
    backface-visibility: $visibility;
}

// Drop shadows
//
// Note: Deprecated `.box-shadow()` as of v3.1.0 since all of Bootstrap's
// supported browsers that have box shadow capabilities now support it.
@mixin box-shadow($shadow...) {
    -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
    box-shadow: $shadow;
}

// Box sizing
@mixin box-sizing($boxmodel) {
    -webkit-box-sizing: $boxmodel;
    -moz-box-sizing: $boxmodel;
    box-sizing: $boxmodel;
}

// CSS3 Content Columns
@mixin content-columns($column-count, $column-gap: $grid-gutter-width) {
    -webkit-column-count: $column-count;
    -moz-column-count: $column-count;
    column-count: $column-count;
    -webkit-column-gap: $column-gap;
    -moz-column-gap: $column-gap;
    column-gap: $column-gap;
}

// Optional hyphenation
@mixin hyphens($mode: auto) {
    word-wrap: break-word;
    -webkit-hyphens: $mode;
    -moz-hyphens: $mode;
    -ms-hyphens: $mode; // IE10+
    -o-hyphens: $mode;
    hyphens: $mode;
}

// Transformations
@mixin scale($ratio...) {
    -webkit-transform: scale($ratio);
    -ms-transform: scale($ratio); // IE9 only
    -o-transform: scale($ratio);
    transform: scale($ratio);
}

@mixin scaleX($ratio) {
    -webkit-transform: scaleX($ratio);
    -ms-transform: scaleX($ratio); // IE9 only
    -o-transform: scaleX($ratio);
    transform: scaleX($ratio);
}

@mixin scaleY($ratio) {
    -webkit-transform: scaleY($ratio);
    -ms-transform: scaleY($ratio); // IE9 only
    -o-transform: scaleY($ratio);
    transform: scaleY($ratio);
}

@mixin skew($x, $y) {
    -webkit-transform: skewX($x) skewY($y);
    -ms-transform: skewX($x) skewY($y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
    -o-transform: skewX($x) skewY($y);
    transform: skewX($x) skewY($y);
}

@mixin translate($x, $y) {
    -webkit-transform: translate($x, $y);
    -ms-transform: translate($x, $y); // IE9 only
    -o-transform: translate($x, $y);
    transform: translate($x, $y);
}

@mixin translate3d($x, $y, $z) {
    -webkit-transform: translate3d($x, $y, $z);
    transform: translate3d($x, $y, $z);
}

@mixin scale3d($x, $y, $z) {
    -webkit-transform: scale3d($x, $y, $z);
    transform: scale3d($x, $y, $z);
}

@mixin rotate($degrees) {
    -webkit-transform: rotate($degrees);
    -ms-transform: rotate($degrees); // IE9 only
    -o-transform: rotate($degrees);
    transform: rotate($degrees);
}

@mixin rotateX($degrees) {
    -webkit-transform: rotateX($degrees);
    -ms-transform: rotateX($degrees); // IE9 only
    -o-transform: rotateX($degrees);
    transform: rotateX($degrees);
}

@mixin rotateY($degrees) {
    -webkit-transform: rotateY($degrees);
    -ms-transform: rotateY($degrees); // IE9 only
    -o-transform: rotateY($degrees);
    transform: rotateY($degrees);
}

@mixin perspective($perspective) {
    -webkit-perspective: $perspective;
    -moz-perspective: $perspective;
    perspective: $perspective;
}

@mixin perspective-origin($perspective) {
    -webkit-perspective-origin: $perspective;
    -moz-perspective-origin: $perspective;
    perspective-origin: $perspective;
}

@mixin transform-origin($origin) {
    -webkit-transform-origin: $origin;
    -moz-transform-origin: $origin;
    -ms-transform-origin: $origin; // IE9 only
    transform-origin: $origin;
}

@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

// Transitions
@mixin transition($transition...) {
    -webkit-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

@mixin transition-property($transition-property...) {
    -webkit-transition-property: $transition-property;
    transition-property: $transition-property;
}

@mixin transition-delay($transition-delay) {
    -webkit-transition-delay: $transition-delay;
    transition-delay: $transition-delay;
}

@mixin transition-duration($transition-duration...) {
    -webkit-transition-duration: $transition-duration;
    transition-duration: $transition-duration;
}

@mixin transition-timing-function($timing-function) {
    -webkit-transition-timing-function: $timing-function;
    transition-timing-function: $timing-function;
}

@mixin transition-transform($transition...) {
    -webkit-transition: -webkit-transform $transition;
    -moz-transition: -moz-transform $transition;
    -o-transition: -o-transform $transition;
    transition: transform $transition;
}

// transition custom
@function prefix($property, $prefixes: webkit moz o ms) {
    $vendor-prefixed-properties: transform background-clip background-size;
    $result: ();
    @each $prefix in $prefixes {
        @if index($vendor-prefixed-properties, $property) {
            $property: -#{$prefix}-#{$property};
        }
        $result: append($result, $property);
    }
    @return $result;
}

@function trans-prefix($transition, $prefix: moz) {
    $prefixed: ();
    @each $trans in $transition {
        $prop-name: nth($trans, 1);
        $vendor-prop-name: prefix($prop-name, $prefix);
        $prop-vals: nth($trans, 2);
        $prefixed: append($prefixed, $vendor-prop-name $prop-vals, comma);
    }
    @return $prefixed;
}

@mixin transitionCustom($values...) {
    $transitions: ();
    @each $declaration in $values {
        $prop: nth($declaration, 1);
        $prop-opts: ();
        $length: length($declaration);
        @if $length>=2 {
            @for $i from 2 through $length {
                $prop-opts: append($prop-opts, nth($declaration, $i));
            }
        }
        $trans: $prop,
        $prop-opts;
        $transitions: append($transitions, $trans, comma);
    }
    -webkit-transition: trans-prefix($transitions, webkit);
    -moz-transition: trans-prefix($transitions, moz);
    -o-transition: trans-prefix($transitions, o);
    transition: $values;
}

// User select
// For selecting text on the page
@mixin user-select($select) {
    -webkit-user-select: $select;
    -moz-user-select: $select;
    -ms-user-select: $select; // IE10+
    user-select: $select;
}

// mouse grab
@mixin grab-cursor {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
}

@mixin grabbing-cursor {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing;
}

@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
    @each $fs-breakpoint,
    $fs-font-size in $fs-map {
        @if $fs-breakpoint==null {
            @include make-font-size($fs-font-size);
        }
        @else {
            @if map-has-key($fs-breakpoints, $fs-breakpoint) {
                $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
                @media screen and (min-width: $fs-breakpoint) {
                    @include make-font-size($fs-font-size);
                }
            }
        }
    }
}

@mixin make-font-size($fs-font-size) {
    // If $fs-font-size is a list, include both font-size, line-height and letter-spacing
    @if type-of($fs-font-size)=="list" {
        font-size: nth($fs-font-size, 1);
        @if (length($fs-font-size) > 1) {
            line-height: nth($fs-font-size, 2);
        }
        @if (length($fs-font-size) > 2) {
            letter-spacing: nth($fs-font-size, 3);
        }
    }
    @else {
        font-size: $fs-font-size;
    }
}

@mixin box-style{
    @include box-shadow(0 12px 24px 0 rgba(10, 47, 106, 0.04));
    @include border-radius(4px);
    border: solid 1px #dddddd;
    background-color: #ffffff;
}
@mixin heading1{
    font-size: 28px;
    color: #14376f;
    font-family: $mirpuri-bold;
    font-weight: 700;
    @include breakpoints(medium){
        font-size: 24px;
    }
}
@mixin heading-faq{
    font-family:$mirpuri-bold;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: 0.86px;
    color: #14376f;
    @include breakpoints(medium){
        font-size: 20px;
    }
}
@mixin invite-text{
    font-family: $mirpuri-book;
    color: #333333;
    letter-spacing: 0.5px;
    line-height: 1.7;
    font-size: 15px;
    @include breakpoints(small){
        font-size: 14px;
    }
}
@mixin wrap-flx{
    display: flex;
    flex-wrap: wrap;
    position: relative;
}














.scrollbarDesign1,
%scrollbarDesign1 {
    &::-webkit-scrollbar{
        width: 5px;
        @include breakpoints(small){
            width: 5px;
        }
    }
    &::-webkit-scrollbar-track{
        background-color: #e9e9e9;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $primary-color-blue;
        cursor: pointer;
        width: 5px;
        @include breakpoints(small){
            width: 6px;
            border-width: 2px;
        }
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #077db4; 
    }
}
