.accordion__section_main {
  margin: 30px 31px 15px;
  @media screen and (max-width: 600px) {
    margin: 0 0 15px 0;
    padding: 11.8px 18px 11.8px 18px;
  }
  padding: 10.3px 25px 10.3px 25px;
  @media screen and (max-width: 300px) {
    padding: 10px 5px !important;
  }
  border-radius: 2px;
  border: solid 1px #dadada;
  background-color: #ffffff;
  cursor: pointer;
  margin-top: 14px;
  margin-bottom: 14px;
}

.accordion_heading {
  width: max-content;
  margin: 2px 371px 0px 0;
  font-family: MaisonNeue;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #2f2f2f;
}

.accordion_content {
  margin: 10px 25px 0 1px;
  @media screen and (max-width: 600px) {
    margin: 13px 25px 0 1px;
  }
  font-family: MaisonNeue;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: 0.46px;
  color: #616161;
  line-height: 1.69;
  letter-spacing: 0.46px;
}

.accordion_chevron {
  float: right;
  margin-top: -24px;
}

.rotate {
  transform: rotate(90deg);
}
