.howto-play-wrap {
  .Faqs {
    padding: 32px 17px 35px 30px;
    background-color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    .accordion__section_main {
      .accordion_chevron2 {
        margin-top: -68px !important;
        @media screen and (max-width: 1039px) {
          margin-top: -38px !important;
        }
        @media screen and (max-width: 500px) {
          margin-top: -42px !important;
        }
        @media screen and (max-width: 400px) {
          margin-top: -68px !important;
        }
      }
      .accordion_chevron2 {
        @media screen and (width: 375px) {
          margin-top: -68px !important;
        }
      }
      .accordion_chevron {
        margin-top: -40px;
        @media screen and (max-width: 350px) {
          margin-top: -65px !important;
        }
        .accordion__icon {
          background: url(https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/+.svg);
          background-repeat: no-repeat;
          background-position: 50%;
          .path {
            display: none !important;
          }
        }
        .rotate {
          background: url(https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/minus.png) !important;
          background-repeat: no-repeat;
          background-position: 50%;
          transform: none;
          transition: none;
          width: 5px;
        }
      }
      margin: 0;
      border: none;
      padding: 0;
      .accordion_heading {
        margin: 2px 371px 10px 0;
        font-family: "MaisonNeue";
        font-size: 20px;
        @media screen and (max-width: 300px) {
          font-size: 18px;
        }
        width: 95%;
        @media screen and (max-width: 767px) {
          width: 90%;
        }
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: #000;
      }
      .accordion_content {
        font-family: "MaisonNeue";
        font-size: 13px;
        font-weight: 400;
        margin: 0;
        font-stretch: normal;
        height: 250px;
        @media screen and (max-width: 300px) {
          font-size: 11px;
        }
        font-style: normal;
        line-height: 1.62;
        letter-spacing: normal;
        color: #616161;
        padding: 16px 8px 0 0;
        text-align: left;
      }
    }
    .header {
      padding: 0 0;
    }

    p {
      h3 {
        @include heading-faq;
        display: inline-block;
      }
      .leftfaq {
        font-family: $mirpuri-book;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        letter-spacing: 0.86px;
        color: #14376f;
        float: left;
      }
      .rightviewall {
        float: right;
        .btn-viewall {
          border: none;
          text-decoration: underline;
          background-color: transparent;
          outline: none;
          font-family: $industry-Medium;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          padding-right: 0px;
          line-height: normal;
          letter-spacing: 0.75px;
          color: #14376f;
        }
      }
    }

    .accordions {
      margin-top: 19px;

      .rotate {
        transform: none !important;
        background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/-.svg") !important;
        background-repeat: no-repeat;
        background-position: center;
      }

      .accordion__icon {
        background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/+.svg");
        background-repeat: no-repeat;
        background-position: center;

        path {
          display: none;
        }
      }

      .accordion__title {
        font-family: $mirpuri-book;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: #000000;
      }
      .accordion__content {
        background-color: white;
        overflow: hidden;
        transition: max-height 0.6s ease;
        border-right: none !important;
        border-bottom: none !important;
        border-left: none !important;

        .accordion__text {
          font-family: $mirpuri-book;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.62;
          letter-spacing: normal;
          color: #616161;
          padding: 16px 8px 0px 0px;
          text-align: left;
        }
      }

      .accordion {
        border-top: none !important;
        border-right: none !important;
        border-left: none !important;
        background-color: white;
        height: 46px;
        color: #444;
        cursor: pointer;
        padding: 18px 0;
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        transition: background-color 0.6s ease;
      }
    }
  }
  .leaderord {
    border: 0;
  }
  .howto-play-left {
    .howto-play-listing {
      margin: 30px 0 0 22px;
      @include breakpoints(small) {
        margin-top: 20px;
      }
      p {
        margin: 0;
        padding: 0 0 30px 40px;
        counter-increment: section;
        position: relative;
        border-left: 2px solid #4d964c;
        @include breakpoints(small) {
          padding: 0 0 20px 25px;
        }
        &:before {
          content: counter(section);
          left: -24px;
          margin-right: -20px;
          position: absolute;
          border-radius: 50%;
          padding: 4px;
          height: 45px;
          width: 45px;
          background-color: white;
          text-align: center;
          color: #4d964c;
          border: 2px solid #4d964c;
          font-size: 24px;
          font-family: $mirpuri-book;
          @include breakpoints(small) {
            font-size: 20px;
            height: 40px;
            width: 40px;
          }
        }
        .steps-head {
          font-size: 16px;
          color: #4d964c;
          font-family: $industry-bold;
          letter-spacing: 0.5px;
          padding-top: 10px;
          margin-bottom: 10px;
          @include breakpoints(small) {
            font-size: 14px;
          }
        }
        h2 {
          font-family: $mirpuri-bold;
          font-size: 22px;
          color: $primary-color-blue;
          margin-bottom: 15px;
          font-weight: 700;
          @include breakpoints(small) {
            font-size: 19px;
          }
          span {
            font-size: 19px;
            color: $text-grey;
            display: block;
            font-family: $mirpuri-book;
            margin: 20px 0 25px;
            @include breakpoints(large) {
              font-size: 18px;
            }
          }
        }
        .steps-text {
          color: $text-grey;
          font-family: $mirpuri-book;
          letter-spacing: 0.5px;
          font-size: 15px;
          line-height: 1.6;
          @include breakpoints(small) {
            font-size: 13px;
          }
          span {
            color: #1454bb;
          }
        }
      }
    }
    .howto-table-head {
      font-size: 18px;
      font-family: $mirpuri-bold;
      margin: 30px 0 15px;
      font-weight: 700;
      .red {
        color: $red;
        text-transform: uppercase;
      }
      .green {
        color: $green;
        text-transform: uppercase;
        br {
          text-transform: capitalize !important;
        }
      }
      .blue {
        color: $blue;
        text-transform: uppercase;
        br {
          text-transform: capitalize !important;
        }
      }
    }
    // table device
    .table-device {
      color: $text-grey;
      display: none;
      @include breakpoints(small) {
        display: block;
      }
      .round-head {
        font-size: 16px;
        text-transform: uppercase;
        font-family: $mirpuri-bold;
        margin-bottom: 6px;
      }
      .table-round-inner {
        @include wrap-flx;
        justify-content: space-between;
        margin-bottom: 13px;
        .table-round-inner-left,
        .table-round-inner-right {
          width: 49%;
          font-size: 13px;
          margin-bottom: 6px;
          span {
            font-weight: bold;
          }
        }
      }
    }

    // table desktop
    .table-howto-play {
      @include breakpoints(small) {
        display: none;
      }
      .table-play-inner {
        @include wrap-flx;
        justify-content: space-between;
        font-size: 15px;
        color: $text-grey;
        .table-head,
        .table-body {
          width: 18%;
          margin-bottom: 10px;
          &:nth-child(1) {
            width: 30%;
          }
          &:nth-child(2),
          &:nth-child(3) {
            width: 12%;
          }
        }
        .table-head {
          font-family: $mirpuri-bold;
        }
        .table-body {
          font-family: $mirpuri-book;
          &:first-child {
            font-weight: bold;
          }
        }
      }
    }
  }
  .invite-body-outer {
    .invite-body-inner {
      .invite-inner-left {
        padding: 50px 50px 40px 35px;
        @include breakpoints(small) {
          padding: 30px 15px 20px 15px;
        }
        h1 {
          text-align: left;
        }
      }
    }
  }
}
